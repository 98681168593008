<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import countries from '@/shared/countries.json'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import createHttpClient from '@/api/httpClient'
import MessageService from '@/components/feedback/message/messageService'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import ChangeSubscription from '@/components/ChangeSubscription.vue'
import { SubscriptionData } from '@/shared/subscription'
import { useStripe } from '@/shared/payment'
import { useDisplay, useTheme } from 'vuetify'
import { InformationData } from '@/api/account/information.d'
import { useUserStore } from '@/store/user/userStore'
import {
  Package,
  Type,
  ChangeSubscriptionData,
  PaymentResponse,
} from '@/api/subscription-packages/package.d'
import { TvaNumberRegex } from '@/utils/regex'
import PackagesCard from '@/components/subscription-packages/PackagesCard.vue'
import PaymentField from '@/components/inputs/PaymentField.vue'
import Address from '@/components/inputs/Address.vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'

const paymentRef = ref(null)
const subscriptionStore = useSubscriptionStore()
const httpClient = createHttpClient()
const globalErrors = ref()
const information = ref<InformationData>()
const userStore = useUserStore()

const router = useRouter()
const { t } = useI18n()
const emit = defineEmits(['update:company', 'update:close'])

const requiredRules = [v => !!v || `${t('dashboard.subscription.required')}`]
const loading = ref(false)
const userTva = useUserTvaStore()
const useAlreadySave = ref(true)

const currentChanging = computed(() => {
  return subscriptionStore.currentChangingPlan
})

const form = ref({
  newSubscriptionLevelId: currentChanging.value.id,
  newCountry: '',
  newCity: '',
  newPostalCode: '',
  newAddress: '',
  newCompany: '',
  newTVANumber: '',
})

const billingAddress = ref({
  addressCompany: '',
  postalCodeCompany: '',
  cityCompany: '',
})

const isValidForm = ref(false)
const isInitializing = ref(false)
const choice = ref('')
const displayPriceWithTva = ref(false)
const loadingPrice = ref(false)
const getValidType = computed(() => {
  return subscriptionStore.getSubscriptionType()
})

const redirectSuccess = () => {
  MessageService.success(t('dashboard.subscription.change_with_success'))
  loading.value = false
  subscriptionStore.toggleSubscription()
  userStore.refreshToken()
  router.push({
    name: 'DashboardSubscription',
  })
}

const onSubmit = async () => {
  if (disabledWhenChanging.value) return false

  globalErrors.value = ''
  if (!isValidForm.value) return false

  try {
    loading.value = true

    form.value = {
      ...form.value,
      newAddress: billingAddress.value.addressCompany,
      newCity: billingAddress.value.cityCompany,
      newPostalCode: billingAddress.value.postalCodeCompany,
    }

    if (displayPaymentDetail.value) {
      if (useAlreadySave.value) {
        // CB déja enrégistré
        if (subscriptionStore.isCardExpired) {
          // check expired CB
          globalErrors.value = t('expired-card')
          loading.value = false
          return false
        }
        await subscriptionStore
          .changeSubscriptionPlan(currentChanging.value.id)
          .then(async (result: PaymentResponse) => {
            if (result.status === 'succeeded') {
              redirectSuccess()
            } else {
              // require action 3D secure
              await paymentRef.value
                .confirmCardPayment(result.clientSecret, result.paymentMethodId)
                .then(async () => {
                  const confirm = await subscriptionStore.confirmSubscription({
                    subscriptionLevelId: currentChanging.value.id,
                    paymentIntentId: result.paymentIntentId,
                  })
                  if (confirm.status) {
                    redirectSuccess()
                    return
                  } else {
                    loading.value = false
                  }
                })
            }
          })
        loading.value = false
      } else {
        await create_pm()
      }
    } else {
      await subscriptionStore
        .changeSubscriptionPlan(currentChanging.value.id)
        .then(async (result: PaymentResponse) => {
          if (result.status === 'succeeded') {
            redirectSuccess()
          } else {
            // require action 3D secure
            await paymentRef.value
              .confirmCardPayment(result.clientSecret, result.paymentMethodId)
              .then(async () => {
                const res = await subscriptionStore.confirmSubscription({
                  subscriptionLevelId: currentChanging.value.id,
                  paymentIntentId: result.paymentIntentId,
                })
                if (res.status) {
                  redirectSuccess()
                  return
                } else {
                  loading.value = false
                }
              })
              .catch(() => {
                loading.value = false
              })
          }
        })
    }
  } catch (error) {
    console.error(error)
    loading.value = false
  }
}

// save new user Card
const create_pm = async () => {
  const res = await paymentRef.value.paymentMethod()
  if (res.status) {
    await subscriptionStore
      .updateUserCard(res.paymentMethodId)
      .then(async clientSecret => {
        paymentRef.value
          .confirmCardSetup(clientSecret, res.paymentMethodId) // stocker la CB pour une utilisation futur
          .then(async response => {
            if (response.status) {
              subscriptionStore
                .changeSubscriptionPlan(currentChanging.value.id) // confirmer la changement de formule
                .then(async (result: PaymentResponse) => {
                  if (result.status === 'succeeded') {
                    redirectSuccess()
                    return
                  } else {
                    // require action 3D secure
                    await paymentRef.value
                      .confirmCardPayment(
                        result.clientSecret,
                        result.paymentMethodId,
                      )
                      .then(async () => {
                        const res = await subscriptionStore.confirmSubscription(
                          {
                            subscriptionLevelId: currentChanging.value.id,
                            paymentIntentId: result.paymentIntentId,
                          },
                        )
                        if (res.status) {
                          redirectSuccess()
                          return
                        } else {
                          loading.value = false
                          MessageService.error('')
                        }
                      })
                  }
                })
                .catch(_ => {
                  loading.value = false
                  MessageService.error('')
                })
            } else {
              globalErrors.value = response.error
              loading.value = false
              MessageService.error('')
            }
          })
      })
  } else {
    globalErrors.value = res.error
    loading.value = false
    MessageService.error('')
    return false
  }
}

const tva = ref(0)
const numberError = ref('')
const validVat = ref(false)

watch(
  () => subscriptionStore.changeSubscription,
  () => {
    isInitializing.value = true
    if (
      subscriptionStore.cardData &&
      subscriptionStore.cardData.lastFour &&
      !subscriptionStore.isCardExpired
    ) {
      useAlreadySave.value = true
    } else {
      useAlreadySave.value = false
    }
    choice.value = getValidType.value

    setTimeout(() => {
      isInitializing.value = false
    }, 0)
  },
  { immediate: true },
)

watch(
  () => form.value.newCountry,
  async () => {
    if (isInitializing.value) return false

    loadingPrice.value = true
    displayPriceWithTva.value = true

    if (form.value.newTVANumber) {
      tva.value = 0
    } else {
      tva.value = await subscriptionStore.tvaByCountry(form.value.newCountry)
    }

    const tvaPrice =
      subscriptionStore.currentChangingPlan.price * (1 + tva.value / 100)

    subscriptionStore.currentChangingPlan = {
      ...subscriptionStore.currentChangingPlan,
      priceWithTva: tvaPrice,
    }
    loadingPrice.value = false
  },
)

// apply TVA change
const applyChange = async () => {
  if (form.value.newTVANumber && validVat.value) {
    displayPriceWithTva.value = false
    loadingPrice.value = true
    tva.value = 0
  } else {
    displayPriceWithTva.value = true
    tva.value = await subscriptionStore.tvaByCountry(form.value.newCountry)
  }
  const tvaPrice =
    subscriptionStore.currentChangingPlan.price * (1 + tva.value / 100)

  subscriptionStore.currentChangingPlan = {
    ...subscriptionStore.currentChangingPlan,
    priceWithTva: tvaPrice,
  }
  loadingPrice.value = false
}

watch(
  () => form.value.newTVANumber,
  v => {
    if (isInitializing.value) return false

    numberError.value = ''
    if (!v) return true

    const value = v.replace(/\s+/g, '')

    validVat.value = TvaNumberRegex.test(value)

    if (!validVat.value) {
      numberError.value = 'Numéro incorrect'
    }
  },
  { flush: 'post' },
)

const the_price = ref(null)
const the_tva = ref(null)

onMounted(() => {
  if (!subscriptionStore.userInformation) return
  information.value = subscriptionStore.userInformation

  form.value.newCompany = information.value.nameCompany
  form.value.newCountry = information.value.countryCompany
  form.value.newAddress = information.value.addressCompany
  form.value.newTVANumber = information.value.tvaNumberCompany
  form.value.newCity = information.value.cityCompany
  form.value.newPostalCode = information.value.postalCodeCompany

  billingAddress.value.addressCompany = information.value.addressCompany
  billingAddress.value.cityCompany = information.value.cityCompany
  billingAddress.value.postalCodeCompany = information.value.postalCodeCompany

  tva.value = subscriptionStore.subscriptionStatus['tva']

  the_price.value = subscriptionStore.subscriptionStatus['actualPrice']
  the_tva.value = subscriptionStore.subscriptionStatus['tva']

  if (tva.value > 0) {
    displayPriceWithTva.value = true
    const tvaPrice =
      subscriptionStore.currentChangingPlan.price * (1 + tva.value / 100)

    subscriptionStore.currentChangingPlan = {
      ...subscriptionStore.currentChangingPlan,
      priceWithTva: tvaPrice,
    }
  }
})

const displayCurrentSubscriptionBtn = computed(() => {
  const currentSubscription = getValidType.value

  if (currentSubscription === subscriptionStore.changingPlan.id) {
    return true
  }

  return false
})

const loadTva = computed(() => {
  return userTva.loadTva
})

watch(
  () => [choice.value],
  async () => {
    const currentSubscription = getValidType.value

    await subscriptionStore.changingPlanChoice(
      SubscriptionData[choice.value][0],
      tva.value,
    )

    if (currentSubscription === subscriptionStore.changingPlan.id) {
      subscriptionStore.isChangingPlan = false
    } else {
      subscriptionStore.isChangingPlan = true
    }
  },
  { deep: true },
)

const closeChangeSubscription = async () => {
  if (loading.value) return false
  subscriptionStore.toggleSubscription()
  await subscriptionStore.fetchSubscriptionStatus()
}

const formatCardNumber = computed(() => {
  return `**** **** **** ${subscriptionStore.cardData.lastFour}`
})

const formatExpired = computed(() => {
  return `${String(subscriptionStore.cardData.expiryMonth).padStart(2, '0')} / ${subscriptionStore.cardData.expiryYear}`
})

const displayPaymentDetail = computed(() => {
  if (subscriptionStore.trialRemainingDays > 0) {
    // en période d'essai
    if (choice.value === 'lifetime') {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
})

const disabledWhenChanging = computed(() => {
  if (
    subscriptionStore.subscriptionStatus['status'] === 'canceled' ||
    subscriptionStore.subscriptionStatus['status'] === 'incomplete_expired' ||
    subscriptionStore.subscriptionStatus['status'] === 'unpaid'
  ) {
    return false
  }

  if (
    subscriptionStore.subscriptionStatus['status'] !== 'active' &&
    subscriptionStore.subscriptionStatus['type'] === 'monthly' &&
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] <= 40
  ) {
    if (subscriptionStore.getSubscriptionType() === choice.value) {
      return true
    }
  }

  return false
})
</script>

<template>
  <v-dialog
    :model-value="subscriptionStore.changeSubscription"
    persistent
    max-width="700"
    transition="dialog-top-transition"
  >
    <v-card color="white" rounded="xl">
      <v-card-title
        class="py-3 d-flex justify-center w-full align-center bg-surface-lighten-1"
      >
        <span class="font-weight-bold title">{{
          t('dashboard.subscription.change_subscription_title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="closeChangeSubscription"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="onSubmit" v-model="isValidForm">
          <div class="card-body mb-3">
            <div class="d-flex justify-center">
              <PackagesCard
                style="max-width: 350px"
                :classes="'border-md border-secondary border-opacity-100 rounded-xl pa-2'"
                :displayAction="false"
                :showFeature="false"
                :thePackage="currentChanging"
                :displayPriceWithTva="displayPriceWithTva"
                :loadingPrice="loadingPrice"
              />
            </div>
          </div>

          <v-container class="pa-xs-0 px-md-10">
            <div class="text-center billing-info-title">
              {{ t('dashboard.subscription.billing_info') }}
            </div>

            <template v-if="displayPaymentDetail">
              <div v-if="subscriptionStore.cardData.lastFour">
                <v-radio-group
                  class="d-flex justify-center text-center"
                  inline
                  hide-details
                  v-model="useAlreadySave"
                >
                  <v-radio
                    color="secondary"
                    :readonly="loading"
                    :label="$t('use_already_save')"
                    :value="true"
                    class="text-rigth"
                  ></v-radio>

                  <v-radio
                    color="secondary"
                    :readonly="loading"
                    :label="$t('new_card')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>
            </template>

            <template v-if="displayPaymentDetail">
              <div
                v-if="
                  subscriptionStore.cardData &&
                  subscriptionStore.cardData.lastFour &&
                  useAlreadySave
                "
                class="d-sm-flex ga-sm-2"
              >
                <div class="w-sm-50 mb-sm-0 mb-2">
                  <label>{{ t('register.card_number') }}</label>
                  <p class="text-disabled">{{ formatCardNumber }}</p>
                </div>

                <div class="w-sm-50">
                  <label>{{ t('expired') }}</label>
                  <p class="text-disabled">{{ formatExpired }}</p>
                </div>
              </div>
            </template>

            <template v-if="displayPaymentDetail">
              <div class="d-flex flex-column ga-sm-2" v-if="!useAlreadySave">
                <label>{{ t('register.card_number') }}</label>
                <PaymentField ref="paymentRef" />
              </div>
            </template>

            <template v-if="false">
              <div class="d-sm-flex align-center ga-2">
                <div class="w-sm-50 w-100">
                  <label>Pays</label>
                  <v-autocomplete
                    rounded="lg"
                    density="compact"
                    v-model="form.newCountry"
                    :items="countries"
                    :rules="requiredRules"
                    clearable
                  />
                </div>

                <div class="py-0 w-sm-50 w-100">
                  <label>% de TVA</label>

                  <v-text-field
                    readonly
                    rounded="lg"
                    v-model="tva"
                    class="text-disabled"
                    density="compact"
                  />
                </div>
              </div>

              <div>
                <div class="d-flex align-center">
                  <label>{{ t('dashboard.subscription.tva_number') }}</label>
                  <v-tooltip :text="$t('register.tva_infobulle')">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        size="x-small"
                        class="cursor-pointer"
                        color="#757575"
                        v-bind="props"
                        >mdi-information</v-icon
                      >
                    </template>
                  </v-tooltip>
                </div>
                <v-text-field
                  rounded="lg"
                  density="compact"
                  v-model="form.newTVANumber"
                  hide-details="auto"
                  class="mb-2"
                  @blur="() => applyChange()"
                  :error-messages="numberError"
                ></v-text-field>
              </div>

              <div class="">
                <Address v-model="billingAddress" />

                <v-card color="white" elevation="0" border v-if="false">
                  <v-card-text border class="pa-0">
                    <div class="pa-3">
                      <input
                        class="pa-0 no-focus w-100"
                        :placeholder="
                          $t('register.billing_address_placeholder')
                        "
                        v-model="form.newAddress"
                      />
                    </div>
                    <v-divider />
                    <div class="d-sm-flex address">
                      <div class="w-sm-50 border-custom pa-3">
                        <input
                          class="pa-0 no-focus w-100"
                          :placeholder="$t('register.postal_code')"
                          v-model="form.newPostalCode"
                        />
                      </div>
                      <div class="w-sm-50 pa-3">
                        <input
                          class="pa-0 no-focus w-100"
                          :placeholder="$t('register.city')"
                          v-model="form.newCity"
                        />
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </template>

            <div class="text-red text-caption text-center mt-2">
              {{ globalErrors }}
            </div>

            <div class="d-flex justify-center">
              <v-btn
                :disabled="disabledWhenChanging"
                :loading="loading"
                type="submit"
                color="white"
                class="bg-secondary px-5 mt-5 w-sm-75 mx-auto"
                >{{
                  t('dashboard.subscription.change_subscription_btn')
                }}</v-btn
              >
            </div>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.card-body {
  width: 389px;
  margin: auto;
}

.no-focus:focus {
  outline: none;
  box-shadow: none;
}
.border-custom {
  border-right: 1px solid #ccc;
}

.card-body {
  width: 416px;
  margin: auto;
}

.billing-info-title {
  color: #616161;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.current_subscription_btn {
  font-size: 13px;
}

label {
  font-size: 14px;
}

@media (max-width: 599px) {
  .card-body {
    width: 100%;
    margin: auto;
  }

  .border-custom {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
</style>
