import {
  CalendarConfig,
  PreactViewComponent,
  createCalendar,
  viewDay,
  viewWeek,
  viewMonthGrid,
  viewMonthAgenda,
} from '@holitime/schedule-x-calendar'
/* For future features
// import { createDragAndDropPlugin } from '@schedule-x/drag-and-drop'
// import { createResizePlugin } from '@schedule-x/resize'
// import { createEventModalPlugin } from '@schedule-x/event-modal'
*/
import { createCurrentTimePlugin } from '@schedule-x/current-time'
import { createSidebarPlugin } from '@holitime/schedule-x-sidebar'
import {
  CalendarControlsPlugin,
  createCalendarControlsPlugin,
} from '@holitime/schedule-x-calendar-controls'
import {
  useSidebarState,
  calendarsUpdaterPlugin,
} from '@/components/calendar/calendarState'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { CalendarAppSingleton, View } from '@holitime/schedule-x-shared'
import dayjs from 'dayjs'
import { TimeSlotEvent } from '@/store/timeslot/timeSlotEvent'
import EventBus from '@/utils/eventBus'
import {
  VacationEditionData,
  VacationEvent,
  VacationData,
} from '../../store/vacation/vacation'
import { createScrollControllerPlugin } from '@schedule-x/scroll-controller'
import router from '@/router'
import { PractitionerAppointmentEvent } from '@/store/practitioner-appointment/practitionerAppointmentEvent'
import { useDialogPractitionerAppointmentStore } from '@/store/practitioner-appointment/dialogAppointmentStore'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import { useTimeSlotStore } from '@/store/timeslot/timeSlotStore'
import { createHeaderPlugin } from '@holitime/schedule-x-header'
import { shallowRef } from 'vue'
export const scrollController = createScrollControllerPlugin({
  initialScroll: '05:50',
})

interface PluginBase {
  name: string
  init?($app: CalendarAppSingleton): void
  destroy?(): void
}
const { updateSidebarState } = useSidebarState()
const DATE_FORMAT = 'YYYY-MM-DD HH:mm'

const locale = 'fr-FR'

const views: [View<PreactViewComponent>, ...View<PreactViewComponent>[]] = [
  viewDay, // desktop and mobile
  viewWeek, // desktop
  viewMonthGrid, // desktop
  viewMonthAgenda, // mobile
]

// Controls programmatically
export const calendarControls: CalendarControlsPlugin =
  createCalendarControlsPlugin()

const events = []
const plugins: PluginBase[] = [
  // createDragAndDropPlugin(),
  // createResizePlugin(),
  // createEventModalPlugin(),
  calendarControls as unknown as PluginBase,
  createCurrentTimePlugin() as unknown as PluginBase,
  createSidebarPlugin() as unknown as PluginBase,
  calendarsUpdaterPlugin,
  scrollController as unknown as PluginBase,
  createHeaderPlugin(),
]

export enum CalendarID {
  PROFILE_1,
  PROFILE_2,
  PROFILE_3,
}

const calendars = {
  [CalendarID.PROFILE_1]: {
    colorName: 'profile1',
    lightColors: {
      main: '#ebba55',
      container: '#ebba55aa',
      onContainer: '#000',
    },
    darkColors: {
      main: '#fff5c0',
      onContainer: '#fff5de',
      container: '#a29742',
    },
  },
  [CalendarID.PROFILE_2]: {
    colorName: 'profile2',
    lightColors: {
      main: '#f91c45',
      container: '#ffd2dc',
      onContainer: '#59000d',
    },
    darkColors: {
      main: '#ffc0cc',
      onContainer: '#ffdee6',
      container: '#a24258',
    },
  },
  [CalendarID.PROFILE_3]: {
    colorName: 'profile3',
    lightColors: {
      main: '#1cf9b0',
      container: '#dafff0',
      onContainer: '#004d3d',
    },
    darkColors: {
      main: '#c0fff5',
      onContainer: '#e6fff5',
      container: '#42a297',
    },
  },
}

export const colorAppointmentReasons = [
  '#4467C5', // deep blue
  '#1d98ba', // blue
  '#3367c5', // light blue
  '#32cb90', // green-turquoise
  '#9ac465', // green
  '#fd552f', // red-orange
  '#f78d37', // orange
  '#ebba55', // yellow-gold
  '#9a8cd3', // purple
  '#f59ca4', // pink
  '#F5DAD2', // random others...
  '#FCFFE0',
  '#BACD92',
  '#75A47F',
  '#8B322C',
  '#DD5746',
  '#FFCF96',
  '#FF8080',
  '#FB88B4',
  '#F9F07A',
  '#B7C9F2',
  '#9195F6',
  '#337357',
  '#FFD23F',
  '#EE4266',
  '#5E1675',
  '#59D5E0',
  '#F5DD61',
  '#FAA300',
  '#F4538A',
  '#C0D6E8',
  '#FBF8DD',
  '#E9C874',
]

export const profileColors = ['#24DFD5', '#24DFD5', '#1AAAEB']

const isAppointment = () =>
  router.currentRoute.value.name === 'DashboardAppointment'

const isTimeSlot = () => router.currentRoute.value.name === 'DashboardTimeSlot'

const openInFunctionOfRoute = (start, end = null) => {
  if (isAppointment()) {
    const dialogStore = useDialogPractitionerAppointmentStore()
    dialogStore.openDialogWithDateTime(start, end) // e.g. 2024-01-01 12:30
  }
  if (isTimeSlot()) {
    const dialogStore = useDialogTimeSlotStore()
    dialogStore.openDialogWithDateTime(start, end) // e.g. 2024-01-01 12:30
  }
}

const updateVacationEvent = (calendarEvent: VacationEvent) => {
  const vacationEditionData: VacationData = {
    id: calendarEvent.id,
    startDate: calendarEvent.start,
    endDate: calendarEvent.end,

    startMinute: Number(calendarEvent.start.split(':')[1]),
    startHour: Number(calendarEvent.start.split(' ')[1].split(':')[0]),
    // startDay: Number(calendarEvent.start.split(' ')[0].split('-')[2]),
    // startMonth: Number(calendarEvent.start.split(' ')[0].split('-')[1]),
    // startYear: Number(calendarEvent.start.split(' ')[0].split('-')[0]),
    endMinute: Number(calendarEvent.end.split(':')[1]),
    endHour: Number(calendarEvent.end.split(' ')[1].split(':')[0]),
    // endDay: Number(calendarEvent.end.split(' ')[0].split('-')[2]),
    // endMonth: Number(calendarEvent.end.split(' ')[0].split('-')[1]),
    // endYear: Number(calendarEvent.end.split(' ')[0].split('-')[0]),
  }
  EventBus.emit('updateVacation', vacationEditionData)
}

const onClickDate = date => {
  const start = dayjs(date, 'YYYY-MM-DD').add(8, 'h').format(DATE_FORMAT)
  const end = dayjs(date, 'YYYY-MM-DD').add(18, 'h').format(DATE_FORMAT)
  openInFunctionOfRoute(start, end)
}

const onEventUpdate = (
  updatedEvent: TimeSlotEvent | PractitionerAppointmentEvent | VacationEvent,
) => {
  /* KEEP IT FOR LATER feature - drag and drop */
  // if (updatedEvent.type !== 'VacationEvent') {
  //   const practitionerAppointmentStore = usePractitionerAppointmentStore()
  //   const dialogStore = useDialogPractitionerAppointmentStore()
  //   dialogStore.id = updatedEvent.id // used for the update api call
  //   dialogStore.isEditing = true // used in the function to differenciaet between edition and creation
  //   updatedEvent = updatedEvent as PractitionerAppointmentEvent
  //   const validatePayload = {
  //     profile: updatedEvent.profileId,
  //     address: updatedEvent.addressId,
  //     days: updatedEvent.days,
  //     weekRepeat: updatedEvent.weekRepeat === 'yes',
  //     date: updatedEvent.start.split(' ')[0],
  //     startTime: updatedEvent.start.split(' ')[1],
  //     endTime: updatedEvent.end.split(' ')[1],
  //     remote:
  //       updatedEvent.acceptRemote === 'both' ||
  //       updatedEvent.acceptRemote === 'remote',
  //     presential:
  //       updatedEvent.acceptRemote === 'both' ||
  //       updatedEvent.acceptRemote === 'in-person',
  //     appointmentReasons: updatedEvent.selectedAppointmentReasons.map(ar => ({
  //       appointmentReason: ar.id,
  //     })),
  //   }
  //   practitionerAppointmentStore.handleConfirmTimeSlotCreation(
  //     validatePayload,
  //   )
  // } else {
  //   updateVacationEvent(updatedEvent as VacationEvent)
  // }
}

const onEventClick = (
  calendarEvent: TimeSlotEvent | PractitionerAppointmentEvent | VacationEvent,
) => {
  if (calendarEvent.type === 'VacationEvent') {
    updateVacationEvent(calendarEvent as VacationEvent)
    return
  } else if (calendarEvent.type === 'PractitionerAppointmentEvent') {
    EventBus.emit(
      'openDetailsAppointmentWithEvent',
      calendarEvent as PractitionerAppointmentEvent,
    )
  } else if (calendarEvent.type === 'TimeSlotEvent') {
    const dialogStore = useDialogTimeSlotStore()
    dialogStore.openDialogWithEvent(calendarEvent as TimeSlotEvent)
  }
}

const onClickDateTime = dateTime => {
  console.log('datetime ===>>>', dateTime)
  const newTimeMinute = 0

  const start = dayjs(
    dateTime.split(':')[0] + ':' + newTimeMinute,
    'YYYY-MM-DD HH:mm',
  ).format(DATE_FORMAT) // e.g. 2024-01-01 12:00

  const end = dayjs(
    dateTime.split(':')[0] + ':' + newTimeMinute,
    'YYYY-MM-DD HH:mm',
  )
    .add(1, 'h')
    .format(DATE_FORMAT) // e.g. 2024-01-01 13:00

  openInFunctionOfRoute(start, end)
}

const callbacks = {
  /**
   * Is called when:
   * 1. Selecting a date in the date picker
   * 2. Selecting a new view
   * */
  async onRangeUpdate(range) {
    if ('DashboardTimeSlot' === router.currentRoute.value.name) {
      const timeSlotStore = useTimeSlotStore()
      await timeSlotStore.initTimeSlots()
    } else if ('DashboardAppointment' === router.currentRoute.value.name) {
      const practitionerAppointmentStore = usePractitionerAppointmentStore()
      await practitionerAppointmentStore.initAppointments()
    }
  },

  /**
   * Is called when an event is updated through drag and drop
   * */
  onEventUpdate,

  /**
   * Is called when an event is clicked
   * */
  onEventClick,

  /**
   * Is called when clicking a date in the month grid
   * */
  onClickDate,

  /**
   * Is called when clicking somewhere in the time grid of a week or day view
   * e.g. 2024-01-01 13:30
   * */
  onClickDateTime,

  // On sidebar panel icon click
  onToggleSidePanel: isOpen => {
    updateSidebarState(isOpen)
  },

  /**
   * On add vacation click
   */
  onAddTimeOff: () => {
    EventBus.emit('toggleVacationDialog')
  },

  /**
   * Method used on click on the green button "Mes rendez-vous" or "Mes créneaux"
   */
  async onChangeToAppointments() {
    if (isAppointment()) {
      const timeSlotStore = useTimeSlotStore()
      timeSlotStore.initTimeSlots()
      await router.push({ name: 'DashboardTimeSlot' })
    }
    if (isTimeSlot()) {
      const practitionerAppointmentStore = usePractitionerAppointmentStore()
      practitionerAppointmentStore.initAppointments()
      await router.push({ name: 'DashboardAppointment' })
    }
  },
}

const config: CalendarConfig = {
  // ... views, events and other options
  views,
  events,
  plugins,
  locale: locale,
  // ... other configuration
  calendars,
  defaultView: viewWeek.name,
  /**
   * Callbacks for events that occur in the calendar
   * */
  callbacks,
}

export const calendarApp = shallowRef(createCalendar(config))
