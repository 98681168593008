<template>
  <v-autocomplete
    v-bind="$attrs"
    chips
    clearable
    multiple
    clear-on-select
    closable-chips
    color="secondary"
  >
    <template v-slot:chip="{ item, props }">
      <v-chip v-bind="props" color="#757575" rounded="lg">
        {{ item.title }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
