<script setup lang="ts">
import { defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
import PractitionerAvatar from '@/components/PractitionerAvatar.vue'

const { t } = useI18n()

defineProps({
  display: Boolean,
  profileIndex: Number,
  profileSlug: {
    type: String,
    default: '',
  },
  profileAvatar: String,
  isDeleteDraft: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['confirmDelete', 'cancel'])
</script>

<template>
  <v-dialog persistent :model-value="display" width="650">
    <v-card max-width="700" color="white" transition="dialog-top-transition">
      <!-- :text="t('profileDialog.description')"
      :title="t('profileDialog.title')" -->

      <v-card-title
        class="py-3 d-flex justify-center w-full align-center bg-surface-lighten-1"
      >
        <span class="font-weight-bold title">{{
          !isDeleteDraft
            ? t('profileDialog.title')
            : t('profileForm.draft-title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="emit('cancel')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <div class="px-5 py-5">
        <div
          class="border-md border-bright-red border-opacity-100 w-sm-66 rounded-lg mx-auto mb-5 pa-3"
        >
          <div class="d-flex ga-2 align-center">
            <v-avatar size="64" class="border-md">
              <PractitionerAvatar
                width="64"
                height="64"
                v-show="!profileAvatar"
                color="#9E9E9E"
              />
              <v-img
                v-show="profileAvatar"
                :src="profileAvatar"
                :cover="true"
              ></v-img>
            </v-avatar>
            <div>
              <div class="profil-idx">Profil {{ profileIndex }}</div>
              <div class="profil-slug">
                {{ !isDeleteDraft ? profileSlug : 'Profil Non publié' }}
              </div>
            </div>
          </div>
        </div>

        <div class="text-center pb-5">
          <span class="delete-title">{{
            !isDeleteDraft
              ? $t('profileForm.delete-title')
              : $t('profileForm.delete-title-draft')
          }}</span>
          <p v-if="!isDeleteDraft" class="mt-3 delete-desc">
            {{ $t('profileForm.delete-desc') }}
          </p>
        </div>

        <div class="d-sm-flex ga-sm-2">
          <v-btn color="#E0E0E0" class="w-sm-50 w-100" @click="emit('cancel')">
            <span class="font-weight-bold" style="color: #757575">{{
              t('confirmDialog.cancelBtn')
            }}</span>
          </v-btn>

          <v-btn
            color="#FF5353"
            class="w-sm-50 w-100 mt-sm-0 mt-2"
            :text="t('confirmDialog.confirmBtn')"
            @click="emit('confirmDelete')"
          ></v-btn>
        </div>
      </div>

      <!-- <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ms-auto"
          :text="t('confirmDialog.confirmBtn')"
          @click="emit('confirmDelete', false)"
        ></v-btn>

        <v-btn
          class="ms-auto"
          :text="t('confirmDialog.cancelBtn')"
          @click="emit('cancel', false)"
        ></v-btn>
      </template> -->
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.delete-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #757575;
}

.delete-title {
  font-size: 20px;
  font-weight: 500;
  color: #616161;
  line-height: 28px;
}

.profil-slug {
  color: #004838;
  font-size: 23px;
  font-weight: 400;
}

.profil-idx {
  color: #9e9e9e;
  font-size: 13px;
}

@media (max-width: 599px) {
  .title {
    font-size: 16px;
  }
}
</style>
