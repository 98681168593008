<template>
  <v-container fluid class="pa-0">
    <div class="d-flex justify-space-between ga-5">
      <div class="py-0" style="flex: 1">
        <template v-if="subscriptionStore.loadSubscription">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <SubscriptionInfo />

          <div v-if="smAndDown" class="my-5 d-flex flex-column ga-5">
            <v-skeleton-loader
              v-if="subscriptionStore.loadSubscription || !userStore.isLogged"
              class="border-md border-opacity-90 rounded-xl overflow-y-hidden"
              elevation="0"
              color="white"
              min-width="365"
              type="card"
            />

            <template v-else>
              <PackagesCard
                v-if="currentPack"
                :displayPriceWithTva="displayPriceWithTva"
                :displayAction="false"
                :classes="'border-md border-secondary border-opacity-100 rounded-xl pa-2'"
                :showFeature="false"
                :displayMore="false"
                :thePackage="currentPack"
              />
              <v-btn
                v-if="
                  !(
                    subscriptionStore.subscriptionStatus &&
                    subscriptionStore.subscriptionStatus['type'] == 'life' &&
                    subscriptionStore.subscriptionStatus['status'] == 'active'
                  )
                "
                @click="subscriptionStore.toggleSubscription"
                block
                color="secondary"
                >{{ $t('change_subscription') }}</v-btn
              >
              <span
                v-if="
                  subscriptionStore.subscriptionStatus &&
                  subscriptionStore.subscriptionStatus['type'] != 'lifetime' &&
                  subscriptionStore.subscriptionStatus['type'] != 'life' &&
                  subscriptionStore.subscriptionStatus['status'] != 'canceled'
                "
                class="d-flex justify-center cursor-pointer text-decoration-underline cancel-subscription"
                @click="() => subscriptionStore.toggleCancelSubscription()"
                >{{ $t('cancel_my_subscription') }}</span
              >
            </template>
          </div>
        </template>

        <template v-if="subscriptionStore.loadInformation">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <DiscountCodeList
            v-if="
              subscriptionStore.subscriptionStatus &&
              subscriptionStore.subscriptionStatus['type'] != 'life'
            "
          />
          <BillingInfo />
        </template>

        <template v-if="subscriptionStore.loadCard">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <PaymentMethode />
        </template>
      </div>

      <template v-if="!smAndDown">
        <v-skeleton-loader
          v-if="subscriptionStore.loadSubscription || !userStore.isLogged"
          class="border-md border-opacity-90 rounded-xl overflow-y-hidden mx-auto"
          elevation="1"
          color="white"
          min-width="365"
          height="500"
          type="card, sentences, image, table-heading, list-item-two-line"
        />
        <div v-else>
          <PackagesCard
            v-if="currentPack"
            class=""
            style="max-width: 365px"
            :displayAction="false"
            :classes="'border-md border-secondary border-opacity-100 rounded-xl'"
            :showFeature="false"
            displayMore
            :thePackage="currentPack"
            :displayPriceWithTva="displayPriceWithTva"
          />
          <!-- <SubscriptionPlan :plans="SubscriptionData[selectedPlan]" /> -->
          <div>
            <div
              class="d-flex justify-center"
              v-if="currentPack.type !== 'life'"
            >
              <v-btn
                :to="{ name: 'DashboardChangeSubscription' }"
                color="secondary"
                class="mt-3"
                >{{ $t('change_subscription') }}</v-btn
              >
            </div>

            <div
              class="w-66 mx-auto d-flex justify-center mt-3"
              @click="() => subscriptionStore.toggleCancelSubscription()"
            >
              <span
                v-if="
                  subscriptionStore.subscriptionStatus &&
                  subscriptionStore.subscriptionStatus['type'] != 'lifetime' &&
                  subscriptionStore.subscriptionStatus['type'] != 'life' &&
                  subscriptionStore.subscriptionStatus['status'] != 'canceled'
                "
                class="cursor-pointer text-decoration-underline cancel-subscription"
                >{{ $t('cancel_my_subscription') }}</span
              >
            </div>
          </div>
        </div>
      </template>
    </div>

    <v-row>
      <v-col cols="12">
        <template v-if="subscriptionStore.paymentListLoading">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <Payments />
        </template>
      </v-col>
    </v-row>

    <ChangeSubscriptionDialog v-if="subscriptionStore.changeSubscription" />
    <CancelSubscriptionDialog v-if="subscriptionStore.cancelSubscription" />
  </v-container>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import BillingInfo from '@/components/subscription/BillingInfo.vue'
import PaymentMethode from '@/components/subscription/PaymentMethode.vue'
import SubscriptionInfo from '@/components/subscription/SubscriptionInfo.vue'
import Payments from '@/components/subscription/Payments.vue'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import { Plan } from '@/shared/subscription'
import SubscriptionPlan from '@/components/SubscriptionPlan.vue'
import { SubscriptionData } from '@/shared/subscription'
import ChangeSubscription from '@/components/ChangeSubscription.vue'
import ChangeSubscriptionDialog from '@/components/dialog/ChangeSubscriptionDialog.vue'
import { useDisplay, useTheme } from 'vuetify'
import CancelSubscriptionDialog from '../dialog/CancelSubscriptionDialog.vue'
import DiscountCodeList from '@/components/subscription/DiscountCodeList.vue'
import { useUserStore } from '@/store/user/userStore'
import { Package, Type } from '@/api/subscription-packages/package.d'
import PackagesCard from '@/components/subscription-packages/PackagesCard.vue'

const { mdAndDown, mobile, xs, sm, smAndDown, mdAndUp } = useDisplay()
const userStore = useUserStore()
const displayPriceWithTva = ref(false)

const subscriptionStore = useSubscriptionStore()
const { t: $t, d, n } = useI18n()
const userTva = useUserTvaStore()

const selectedPlan = computed(() => {
  if (subscriptionStore.planChoise && subscriptionStore.planChoise.id) {
    return subscriptionStore.planChoise.id
  }
})

onMounted(async () => {
  await subscriptionStore.fetchSubscriptionStatus() // get subscription status
  if (subscriptionStore.subscriptionStatus['tva'] > 0) {
    displayPriceWithTva.value = true

    const tvaPrice =
      subscriptionStore.subscriptionStatus['subscriptionLevel'].price *
      (1 + subscriptionStore.subscriptionStatus['tva'] / 100)

    subscriptionStore.subscriptionStatus['subscriptionLevel'] = {
      ...subscriptionStore.subscriptionStatus['subscriptionLevel'],
      priceWithTva: tvaPrice,
    }
  }
  await subscriptionStore.fetchInformation() // récupération information sur l'entreprise
  await subscriptionStore.fetchCardInfo() // get CB info
  await subscriptionStore.fetchPayments() // get payment
  await userTva.getUserTva() // user TVA
  await subscriptionStore.fetchDiscountCodes() // get subscription status
})

const currentPack = computed(
  () => subscriptionStore.subscriptionStatus['subscriptionLevel'],
)
</script>

<style lang="scss">
.cancel-subscription {
  size: 11.78px;
  color: #9e9e9e;
  font-weight: 600;
  line-height: 17.67px;
}
</style>
