<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import WarningIcon from '@/components/icons/WarningIcon.vue'

const { t } = useI18n()

const { title, description, minTitle, dialog } = defineProps({
  title: {
    required: true,
    type: String,
  },
  minTitle: String,
  description: String,
  dialog: Boolean,
  warningColor: {
    type: String,
    color: '#12C29B',
  },
})

const emit = defineEmits(['update:cancel', 'update:confirm'])
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="500"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title
        class="d-flex justify-center w-full align-center bg-surface-lighten-1"
      >
        <span class="font-weight-bold title">
          {{ title }}
        </span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="emit('update:cancel')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-card-text class="pa-5">
        <div class="d-flex flex-column ga-3">
          <div class="d-flex justify-center">
            <WarningIcon :color="warningColor" />
          </div>

          <div class="text-center py-5">
            <p
              v-if="minTitle"
              class="text-center font-weight-bold text-gray-80"
            >
              {{ minTitle }}
            </p>
            <p v-if="description" class="alert-desc">{{ description }}</p>
          </div>

          <div class="d-sm-flex ga-2">
            <v-btn
              color="#E0E0E0"
              class="w-sm-50 w-100"
              @click="emit('update:cancel')"
            >
              <span style="color: #757575; font-weight: 700">{{
                t('dashboard.appointment.dialogDetails.cancelBtn')
              }}</span>
            </v-btn>

            <v-btn
              class="w-sm-50 w-100 mt-sm-0 mt-2"
              @click="emit('update:confirm')"
              color="secondary"
              >{{ t('dashboard.appointment.dialogDetails.confirmBtn') }}</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.alert-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #757575;
}

@media (max-width: 599px) {
  .title {
    font-size: 14px;
  }
}
</style>
