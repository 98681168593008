<template>
  <v-dialog
    :model-value="dialogStore.isOpen"
    persistent
    transition="dialog-top-transition"
    width="550"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="title-modal font-weight-bold">{{
          dialogStore.isEditing
            ? $t('dashboard.appointment.dialogPopup.titleEdit')
            : $t('dashboard.appointment.dialogPopup.titleCreate')
        }}</span>
        <CloseIcon
          size="24"
          @click="() => dialogStore.toggleDialog()"
          class="mr-4 close-icon cursor-pointer"
        />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex flex-column ga-2 px-12 xxs-padding">
        <v-form ref="form" @submit.prevent="btnForSaveInformation">
          <div class="my-2">
            <label>{{
              $t('dashboard.appointment.dialogPopup.labelProfile')
            }}</label>
            <v-select
              v-if="1 < profileStore.profiles.length"
              v-model="dialogStore.selectedProfile"
              :auto-select-first="false"
              :custom-filter="() => true"
              :items="profileStore.profiles"
              clearable
              item-title="mainDiscipline.name"
              item-value="mainDiscipline.name"
              return-object
              validate-on="lazy"
              density="compact"
              @update:model-value="onProfileChange"
              :rules="required"
              hide-details
              rounded="lg"
              placeholder="selection de profile"
            />
          </div>
          <v-btn
            v-if="dialogStore.isNewPatient && !dialogStore.isEditing"
            @click="handleChoosePatientOlder"
            variant="text"
            block
            density="comfortable"
          >
            <div class="font-weight-bold d-flex align-center ga-3">
              <span>
                {{ $t('dashboard.appointment.dialogPopup.selectOlderPatient') }}
              </span>
            </div>
          </v-btn>
          <div v-if="!dialogStore.isNewPatient">
            <label>Sélection patient</label>
            <div class="d-flex ga-2">
              <v-autocomplete
                v-model="dialogStore.selectedPatient"
                :error-messages="
                  practitionerAppointmentStore.errorMessage('owner')
                "
                :placeholder="
                  $t('dashboard.appointment.dialogPopup.placeholderNamePatient')
                "
                :items="
                  practitionerAppointmentStore.patients as Partial<
                    NewPatientData | PatientAugmentedData
                  >[]
                "
                :auto-select-first="false"
                :custom-filter="() => true"
                :disabled="dialogStore.isEditing"
                clearable
                item-title="fullName"
                item-value="fullName"
                return-object
                validate-on="lazy"
                variant="outlined"
                density="compact"
                rounded="lg"
                @input="onInputPatient"
                @update:search="autocomletePatientByName"
                @update:model-value="
                  handleSelectAutocomplete(
                    $event as unknown as PatientAugmentedData,
                  )
                "
                @update:menu="handlePatientMenuUpdate"
                :rules="required"
                hide-details="auto"
              >
                <template v-slot:append-inner="{ isActive }">
                  <v-btn
                    v-show="loadingPatient && isActive"
                    :loading="loadingPatient"
                    variant="text"
                    icon="mdi-loading"
                    size="small"
                    disabled
                  >
                  </v-btn>
                </template>
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #prepend="">
                      <v-avatar
                        v-if="(item.raw as PatientAugmentedData).firstName"
                        color="surface-variant"
                        size="33"
                        >{{
                          (item.raw as PatientAugmentedData).firstName.slice(
                            0,
                            1,
                          )
                        }}</v-avatar
                      >
                    </template>
                    <div class="text-caption">{{ item.raw.email }}</div>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-btn
                v-if="!dialogStore.isNewPatient && !dialogStore.isEditing"
                @click="() => handleNewPatient()"
                color="#12C29B"
                icon
                size="small"
              >
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>

          <v-expand-transition
            v-if="dialogStore.isNewPatient && !dialogStore.isEditing"
          >
            <form ref="formNewPatient" class="mb-2 d-flex flex-column ga-1">
              <div>
                <label>{{
                  `${$t('dashboard.appointment.dialogPopup.firstName')}*`
                }}</label>
                <v-text-field
                  v-model="dialogStore.newPatientFirstName"
                  hide-details
                  :error-messages="
                    practitionerAppointmentStore.errorMessage(
                      'firstNamePatient',
                    )
                  "
                  density="compact"
                  clearable
                  :rules="required"
                  rounded="lg"
                  :placeholder="
                    $t('dashboard.appointment.dialogPopup.firstName')
                  "
                ></v-text-field>
              </div>
              <div>
                <label>{{
                  `${$t('dashboard.appointment.dialogPopup.lastName')}*`
                }}</label>
                <v-text-field
                  v-model="dialogStore.newPatientLastName"
                  hide-details
                  :error-messages="
                    practitionerAppointmentStore.errorMessage('lastNamePatient')
                  "
                  density="compact"
                  clearable
                  :rules="required"
                  rounded="lg"
                  :placeholder="
                    $t('dashboard.appointment.dialogPopup.lastName')
                  "
                ></v-text-field>
              </div>
              <div>
                <label>{{ `${$t('Email')}*` }}</label>
                <v-text-field
                  v-model="newPatientMail"
                  hide-details
                  :error-messages="
                    practitionerAppointmentStore.errorMessage('emailPatient')
                  "
                  density="compact"
                  clearable
                  :rules="emailRules"
                  rounded="lg"
                  :placeholder="$t('Email')"
                ></v-text-field>
              </div>
              <div>
                <label class="label-custom">{{
                  t('dashboard.account.phoneNumber')
                }}</label>
                <Phone
                  v-model="phoneNumber"
                  :label="$t('accountForm.phone.label')"
                  :show-dial-code="false"
                  rounded
                  @change="onPhoneChange"
                  @valide="isValidPhone = $event"
                />
              </div>
            </form>
          </v-expand-transition>

          <div class="my-2">
            <label>{{
              $t('dashboard.appointment.dialogPopup.placeholderSelectMotive')
            }}</label>
            <v-select
              v-model="dialogStore.selectedAppointmentReason"
              :items="dialogStore.fileteredAppointmentReason"
              rounded="lg"
              density="compact"
              item-title="label"
              item-value="id"
              return-object
              :error-messages="
                errorMessages(dialogStore.selectedAppointmentReason)
              "
              hide-details="auto"
              @update:model-value="updateEnd"
              placeholder="Sélectionner un motif"
            >
              <template #item="{ props, item }">
                <v-list-item v-bind="props">
                  <div class="text-caption">
                    {{
                      $t('dashboard.time-slot.dialogTimeSlot.duration', {
                        duration: item.raw.duration,
                      })
                    }}
                  </div>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <span
            v-if="!dialogStore.fileteredAppointmentReason.length"
            style="font-size: 0.8em"
            class="text-red text-center font-italic"
          >
            {{ $t('dashboard.time-slot.dialogTimeSlot.messageErrorForMotif') }}
          </span>

          <div class="d-flex ga-2">
            <!-- <SelectDateAndTime
              v-model:date="dialogStore.selectedDate"
              v-model:start="dialogStore.selectedStartTime"
              v-model:end="dialogStore.selectedEndTime"
              :end-read-only="true"
              @update:start="updateEnd"
            /> -->
            <div class="w-100">
              <label>Date</label>
              <v-date-input
                prepend-icon=""
                v-model="dialogStore.selectedDate"
                :append-inner-icon="'mdi-calendar-remove'"
                :placeholder="
                  $t('dashboard.time-slot.dialogVacation.datePlaceholder')
                "
                density="compact"
                rounded="lg"
                hide-details="auto"
                hide-actions
                variant="outlined"
                :min="today"
                :rules="required"
              />
            </div>
          </div>

          <div class="d-sm-flex align-center ga-2 mt-2">
            <div class="w-sm-50">
              <label>De</label>
              <SelectHourMinute
                class="w-100"
                v-model:hour="dialogStore.startHour"
                v-model:minute="dialogStore.startMinute"
              />
            </div>

            <div class="w-sm-50 mt-2 mt-sm-0">
              <label>À</label>
              <div class="text-desabled">
                {{ computedEndDate }}
              </div>
            </div>
          </div>

          <div v-if="addressStore.addresses.length > 1" class="my-2">
            <label>Sélection adresse</label>
            <!-- :label="$t('dashboard.time-slot.yourAddress')" -->
            <v-select
              v-model="dialogStore.selectedAddress"
              class="flex-grow-1"
              hide-details
              density="compact"
              item-title="formattedAddress"
              item-value="formattedAddress"
              return-object
              :items="addressStore.addresses"
              rounded="lg"
            >
              <template v-slot:selection="{ item }">
                <span v-if="item.title.length < 50">{{ item.title }}</span>
                <span v-else class="text-black align-self-center">
                  {{ item.title.slice(0, 50) }} ...
                </span>
              </template>
            </v-select>
          </div>

          <!-- For the moment, the practitioner can set acceptRemote even if the reason doesn't allow it -->
          <div class="my-2">
            <label for="">{{
              $t('dashboard.appointment.dialogPopup.modality')
            }}</label>
            <div class="remote-wrapper d-flex align-center justify-start ga-4">
              <v-radio-group
                class="mx-auto ga-2 flex accept-remote items-center"
                inline
                hide-details
                v-model="dialogStore.acceptRemote"
              >
                <v-radio
                  color="secondary"
                  :label="$t('dashboard.time-slot.in-person')"
                  value="in-person"
                ></v-radio>
                <v-radio
                  color="secondary"
                  :label="$t('dashboard.time-slot.remote')"
                  value="remote"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>

          <div class="mb-2" v-if="dialogStore.acceptRemote == 'remote'">
            <label>{{
              $t('dashboard.appointment.dialogPopup.remoteAppointmentLink')
            }}</label>
            <v-text-field
              v-model="dialogStore.remoteAppointmentLink"
              hide-details
              :error-messages="
                practitionerAppointmentStore.errorMessage('meetingLink')
              "
              density="compact"
              rounded="lg"
              clearable
            ></v-text-field>
          </div>

          <div>
            <label>Description</label>
            <rich-editor
              v-model:content="dialogStore.description"
              contentType="html"
              :placeholder="
                $t('dashboard.appointment.dialogPopup.placeholderDescription')
              "
              :toolbar="[
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
              ]"
              :rules="[]"
              :error-messages="[]"
              color="secondary"
            >
            </rich-editor>
          </div>

          <div class="d-sm-flex ga-2 mt-4">
            <v-btn
              v-if="dialogStore.isEditing"
              class="w-sm-50 w-100"
              color="red-darken-1"
              @click="handleDelete"
            >
              {{ $t('common.delete') }}
            </v-btn>

            <v-btn
              v-else
              @click="() => dialogStore.toggleDialog()"
              color="#E0E0E0"
              class="w-sm-50 w-100 mb-2 mb-sm-0"
            >
              <span style="color: #757575; font-weight: 700">Annuler</span>
            </v-btn>

            <v-btn
              class="w-sm-50 w-100"
              variant="flat"
              color="secondary"
              :loading="practitionerAppointmentStore.loading"
              type="submit"
            >
              {{ $t('common.save') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/date-picker.css'
import { computed, nextTick, onMounted, ref, Ref, watch } from 'vue'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import SelectDateAndTime from './SelectDateAndTime.vue'
import { useDialogPractitionerAppointmentStore } from '@/store/practitioner-appointment/dialogAppointmentStore'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import meeting from '@/assets/practitioner-appointment/meeting.png'
import RichEditor from '@/components/inputs/RichEditor.vue'
import { useUserStore } from '@/store/user/userStore'
import { useAddressStore } from '@/store/address/addressStore'
import {
  NewPatientData,
  PatientAugmentedData,
} from '@/store/practitioner-appointment/practitionerAppointmentEvent'
import { useDebounceFn } from '@vueuse/core'
import dayjs from 'dayjs'
import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import { useI18n } from 'vue-i18n'
import { useProfileStore } from '@/store/profile/profileStore'
import { useValidate } from '@/useValidate'
import CloseIcon from '../icons/CloseIcon.vue'
import { storeToRefs } from 'pinia'
import { VDateInput } from 'vuetify/labs/VDateInput'
import SelectHourMinute from './SelectHourMinute.vue'
import Phone from '@/components/inputs/phone/Phone.vue'

const { required } = useValidate()

const { t } = useI18n()
const userStore = useUserStore()
const addressStore = useAddressStore()
const profileStore = useProfileStore()
const dialogStore = useDialogPractitionerAppointmentStore()
const practitionerAppointmentStore = usePractitionerAppointmentStore()
const appointmentReasonStore = useAppointmentReasonStore()
const today = new Date().toISOString().split('T')[0] // Date du jour
const phone = ref('')
const phoneNumber = ref('')
const isValidPhone = ref(false)
const emailRules = computed(() => [
  (v: string) => !!v || t('login.error.emailRequired'),
  (v: string) => /.+@.+\..+/.test(v) || t('login.error.emailInvalid'),
])

const onProfileChange = (event: any) => {
  if (event === null)
    dialogStore.selectedAppointmentReason =
      dialogStore.fileteredAppointmentReason[0]
  dialogStore.selectedAppointmentReason =
    dialogStore.fileteredAppointmentReason.find(ap => ap.profile.id == event.id)
}

const newPatientMail = ref('')

watch(
  () => newPatientMail.value,
  () => {
    const errors = emailRules.value
      .map(rule => rule(newPatientMail.value))
      .filter(result => result !== true)

    if (errors.length > 0) {
      dialogStore.newPatientEmail = ''
    } else {
      dialogStore.newPatientEmail = newPatientMail.value.toLowerCase()
    }
  },
)

watch(
  () => dialogStore.isOpen,
  () => {
    newPatientMail.value = dialogStore.newPatientEmail

    const date = dialogStore.selectedDate
      ? dayjs(dialogStore.selectedDate)
      : dayjs()

    // dialogStore.selectedDate = dayjs(dialogStore.selectedDate, 'YYYY-MM-DD HH:mm').toDate()
    dialogStore.startHour = date.hour()
    dialogStore.startMinute = date.minute()
  },
)

const handlePatientMenuUpdate = async () => {
  if (0 === practitionerAppointmentStore.patients.length) {
    await practitionerAppointmentStore.fillPatients('*')
  }
}

function errorMessages(value: any) {
  if (!value) {
    return ['Une option doit être sélectionnée.']
  }
  return []
}

const btnForSaveInformation = () => {
  if (!dialogStore.isEditing && !formValid) return false

  if (!selectedDate.value) return false

  if (dialogStore.isNewPatient) {
    if (phoneNumber.value && !isValidPhone.value) return false

    dialogStore.newPatientPhone = phone.value
  }

  if (!dialogStore.isEditing) {
    dialogStore.createAppointment()
  } else {
    dialogStore.updateAppointment()
  }
}

onMounted(async () => {
  if (!addressStore.addresses || !addressStore.addresses.length) {
    // userStore.initAddresses()
    addressStore.initAddresses()
  }
  if (!profileStore.profiles || !profileStore.profiles.length) {
    // await userStore.initProfiles()
    await profileStore.initProfiles()
  }
  dialogStore.selectedProfile = profileStore.profiles[0] // select first profile by default
  if (!appointmentReasonStore.appointmentReasons) {
    await appointmentReasonStore.refreshAppointmentReasons()
  }
  // default selected motive
  dialogStore.selectedAppointmentReason =
    dialogStore.fileteredAppointmentReason[0]

  // default search for users
  autocomletePatientByName('')
})
const formNewPatient = ref(null)
const handleChoosePatientOlder = () => {
  dialogStore.isNewPatient = false
  autocomletePatientByName('')
  dialogStore.resetData()
}

const handleNewPatient = () => {
  dialogStore.isNewPatient = true
  nextTick(() => {
    const input = formNewPatient.value.querySelector(
      'input:not([type=hidden]),textarea:not([type=hidden])',
    )
    if (input) {
      setTimeout(() => {
        input.focus()
      }, 0)
    }
  })
}

// const formValid = ref(false)

const {
  selectedPatient,
  selectedAddress,
  selectedAppointmentReason,
  selectedDate,
  acceptRemote,
} = storeToRefs(dialogStore)

const formValid = computed(() => {
  return (
    selectedPatient.value &&
    selectedPatient.value.email &&
    selectedPatient.value.firstName &&
    selectedPatient.value.lastName &&
    (selectedAddress.value || addressStore.addresses.length > 0) &&
    selectedAppointmentReason.value &&
    !!selectedDate.value &&
    acceptRemote.value
    // isInFuture.value
  )
})
const isInFuture = computed(() => {
  if (!dialogStore.selectedDate || !dialogStore.selectedStartTime) return false

  return dayjs(
    `${dialogStore.selectedDate.format('YYYY-MM-DD')} ${dialogStore.selectedStartTime}`,
    'YYYY-MM-DD HH:mm',
  ).isAfter(dayjs())
})

dialogStore.$subscribe((mutation, state) => {
  if (!state.selectedAddress) {
    state.selectedAddress = addressStore.addresses[0]
  }

  /** The case the end time is before start time should never happen, in normal civilization
   * as the end time is calculated in function of the time of the appointment reason.
   *
   * */
  // if (
  //   state.selectedPatient &&
  //   state.selectedPatient.email &&
  //   state.selectedPatient.firstName &&
  //   state.selectedPatient.lastName &&
  //   state.selectedAddress &&
  //   state.selectedAppointmentReason &&
  //   state.selectedDate &&
  //   state.acceptRemote &&
  //   isInFuture.value
  // )
  //   formValid.value = true
  // else {
  //   formValid.value = false
  // }
})

const loadingPatient = ref(false)
const onInputPatient = () => {
  loadingPatient.value = true
}
const autocomletePatientByName = useDebounceFn(async name => {
  loadingPatient.value = true
  if (typeof name !== 'string') return
  await practitionerAppointmentStore.fillPatients(name.trim())
  loadingPatient.value = false
}, 500)

const handleSelectAutocomplete = (patientSelected: PatientAugmentedData) => {
  dialogStore.selectedPatient = patientSelected
}

const handleDelete = () => {
  ConfirmDialogService.confirm({
    title: t('dashboard.appointment.dialogPopup.confirmDeleteAppointment'),
    message: t(
      'dashboard.appointment.dialogPopup.confirmDeleteAppointmentQuetion',
    ),
    optionsConfirm: {
      onCancel() {},
      onConfirm() {
        practitionerAppointmentStore.handleDeleteAppointment(dialogStore.id)
      },
    },
  })
}

const updateEnd = () => {
  const duration = dialogStore.selectedAppointmentReason
    ? dialogStore.selectedAppointmentReason.duration
    : null
  if (!duration) return
  dialogStore.selectedEndTime = dayjs(
    dialogStore.selectedDate + ' ' + dialogStore.selectedStartTime,
    'YYYY-MM-DD H:mm',
  )
    .add(duration, 'minutes')
    .format('YYYY-MM-DD HH:mm')
    .split(' ')[1]
}

const computedEndDate = computed(() => {
  const duration = dialogStore.selectedAppointmentReason?.duration // durée en minutes

  // Conversion des heures et minutes en nombres
  const startHour = Number(dialogStore.startHour)
  const startMinute = Number(dialogStore.startMinute)

  // Création de l'heure de début avec Day.js et ajout de la durée
  const endTime = dayjs()
    .hour(startHour)
    .minute(startMinute)
    .add(duration, 'minute') // Ajout de la durée en minutes

  return endTime.format('HH:mm') // Retourne l'heure formatée HH:mm
})

const onPhoneChange = async phoneObject => {
  isValidPhone.value = phoneObject.valid
  phone.value = phoneObject.number
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
}
.input-every-x-week {
  width: 66px;
  &:deep() input.v-field__input {
    text-align: center;
  }
}
.v-btn-toggle-custom {
  width: fit-content;
  border: 1px solid grey;
  :deep() {
    background-color: #fff;
  }
}
.select-motive {
  max-width: 80%;
}
.accept-remote {
  display: flex;
}
@media screen and (max-width: 540px) {
  .xxs-padding {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .remote-wrapper {
    flex-direction: column;
    gap: 4px !important;
  }
  .close-icon {
    right: -11px;
  }
  :deep() .ql-editor {
    font-size: 12px;
  }
}
</style>
