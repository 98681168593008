// Composables
import { createRouter, createWebHistory } from 'vue-router'
import { useStorage } from '@vueuse/core'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user/userStore'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { usePractitionerPublicProfileStore } from '@/store/practitioner-public-profile/practitionerPublicProfileStore'
import path from 'path'
import { computed } from 'vue'
import { SelectedStoreType } from '@/api/subscription-packages/package.d'

/**
 * Inserts a meta element with the specified Open Graph property and content.
 * @param {string} property - The property value for the meta tag (e.g., 'og:image:width').
 * @param {string} content - The content value for the meta tag.
 * @param {Element} [referenceElement] - Optional reference element to insert the meta tag after. If not provided, the meta tag is appended to the head.
 */
function insertOgMeta(property, content, referenceElement = null) {
  // Step 1: Create the new meta element
  const metaElem = document.createElement('meta')
  metaElem.setAttribute('property', property)
  metaElem.setAttribute('content', content)

  // Step 2: Insert the new element
  if (referenceElement && referenceElement.parentNode) {
    referenceElement.parentNode.insertBefore(
      metaElem,
      referenceElement.nextSibling,
    )
  } else {
    document.querySelector('head').appendChild(metaElem)
  }
}

function capitalizeFirstLetter(text: string): string {
  if (!text) return ''
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

const selectedPack = useStorage('selected-pack', {} as SelectedStoreType)

const selectedSubscription = computed(() => selectedPack.value)

const routes = [
  {
    path: '/dashboard',
    component: () => import('@/layouts/dashboard/LayoutDashboard.vue'),
    children: [
      {
        path: '',
        name: 'DashboardHome',
        component: () => import('@/views/dashboard/Home.vue'),
        meta: {
          ogDescription:
            'La plateforme dédiée aux pratiques de bien-être naturelles et holistiques"',
          title: 'Holitime - Dashboard',
          ogTitle: 'Holitime - Dashboard',
          name: 'Tableau de bord',
        },
      },
      {
        path: '/sponsorship',
        name: 'AffiliateDashboard',
        component: () => import('@/views/AffiliateDashboard.vue'),
        meta: {
          title: 'Holitime - Parrainage',
          name: 'Parrainage',
        },
      },
      {
        path: 'account',
        name: 'DashboardAccount',
        component: () => import('@/views/dashboard/Account.vue'),
        meta: {
          title: 'Holitime - Mon compte',
          name: 'Mon compte',
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.tab) {
            next({ ...to, query: { ...to.query, tab: 'information' } })
          } else {
            next()
          }
        },
      },
      {
        path: 'address',
        name: 'DashboardAddress',
        component: () => import('@/views/dashboard/Addresses.vue'),
        meta: {
          title: 'Holitime - Adresse',
          name: 'Mes Adresses',
        },
      },
      {
        path: 'time-slot',
        name: 'DashboardTimeSlot',
        component: () => import('@/views/dashboard/TimeSlots.vue'),
        meta: {
          title: 'Holitime - Créneaux',
          name: 'Mes Créneaux',
        },
      },
      {
        path: 'appointment',
        name: 'DashboardAppointment',
        component: () => import('@/views/dashboard/Appointment.vue'),
        meta: {
          title: 'Holitime - Rendez-vous',
          name: 'Mes Rendez-vous',
        },
      },
      {
        path: 'profile',
        name: 'DashboardProfile',
        component: () => import('@/views/dashboard/Profiles.vue'),
        meta: {
          title: 'Holitime - Profil',
          name: 'Mes Profils',
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.tab) {
            next({ ...to, query: { ...to.query, tab: 'info' } })
          } else {
            next()
          }
        },
      },
      {
        path: 'subscription',
        name: 'DashboardSubscription',
        component: () => import('@/views/dashboard/Subscription.vue'),
        meta: {
          title: 'Holitime - Abonnement',
          name: 'Mon abonnement',
        },
      },
      {
        path: 'reset-password',
        name: 'ForgotPassword',
        component: () => import('@/views/dashboard/ForgotPassword.vue'),
        meta: {
          title: 'Holitime - réinitialisation du mot de passe',
          name: 'Mot de passe oublié',
        },
      },
      {
        path: 'appointments-patients',
        name: 'DashboardAppointmentsPatients',
        component: () => import('@/views/dashboard/AppointmentPatient.vue'),
        meta: {
          title: 'Holitime - Rendez-vous patients',
          name: 'Mes rendez-vous',
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.tab) {
            next({ ...to, query: { ...to.query, tab: 'upcoming_event' } })
          } else {
            next()
          }
        },
      },
      {
        path: 'avis',
        name: 'DashboardAppointmentsPatientsAvis',
        component: () => import('@/views/dashboard/AppointmentPatient.vue'),
        meta: {
          ogDescription:
            'La plateforme dédiée aux pratiques de bien-être naturelles et holistiques"',
          title: 'Holitime - Rendez-vous patients',
          ogTitle: 'Holitime - Rendez-vous patients',
        },
      },
      {
        path: 'legal-support',
        name: 'DashboardLegalSupport',
        component: () => import('@/views/dashboard/LegalSupport.vue'),
        meta: {
          title: 'Holitime - Accompagnement Juridique',
          name: 'Accompagnement Juridique',
        },
        beforeEnter: (to, from, next) => {
          const authorised = useUserStore().user.legal.isLinkActive
          if (authorised) {
            next()
          } else {
            next({ name: 'DashboardHome' })
          }
        },
      },
      {
        path: 'patient-lists',
        name: 'DashboardPatientLists',
        component: () => import('@/views/dashboard/PatientLists.vue'),
        meta: {
          title: 'Holitime - Mes Patients',
        },
      },
      {
        path: 'discipline-lists',
        name: 'DashboardDisciplines',
        component: () => import('@/views/dashboard/DisciplineLists.vue'),
        meta: {
          title: 'Holitime - Mes Disciplines',
        },
      },
      {
        path: 'pratitionners-lists',
        name: 'DashboardPraticienLists',
        component: () => import('@/views/dashboard/PratitionnerLists.vue'),
        meta: {
          title: 'Holitime - Mes Praticiens',
        },
      },
      {
        path: 'tracking-lists',
        name: 'DashboardTrackingLists',
        component: () => import('@/views/dashboard/Suivis.vue'),
        meta: {
          title: 'Holitime - Ma liste de suivis',
        },
      },
      {
        path: 'profile-lists',
        name: 'DashboardMobileProfilLists',
        component: () => import('@/views/dashboard/ProfileLists.vue'),
        meta: {
          title: 'Listes de profiles',
        },
      },
    ],
  },
  {
    path: '/onboarding',
    component: () => import('@/layouts/dashboard/BlankDashboard.vue'),
    children: [
      {
        path: '',
        name: 'Onboarding',
        component: () => import('@/views/PractitionerOnboarding.vue'),
        meta: {
          title: 'Holitime - Onboarding',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (Home-[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/Home.vue'),
        meta: {
          title: 'Holitime - Bienvenue',
        },
      },
      {
        path: '/praticiens',
        name: 'PractitionerPresentation',
        component: () => import('@/views/PractitionerPresentation.vue'),
        meta: {
          title: 'Holitime - Praticiens',
        },
      },
      {
        path: '/partners',
        name: 'Partners',
        component: () => import('@/views/Ambassador.vue'),
        meta: {
          title: 'Holitime - Partenaires',
        },
      },
      {
        path: '/cgu',
        name: 'CGU',
        component: () => import('@/views/CGU.vue'),
        meta: {
          title: 'Holitime - Conditions generale utilisation',
        },
      },
      {
        path: '/data-policy',
        name: 'DataPolicy',
        component: () => import('@/views/DataPolicy.vue'),
        meta: {
          title: 'Holitime - Politiques',
        },
      },
      // {
      //   path: '/showcase',
      //   name: 'Showcase',
      //   component: () => import('@/views/Showcase.vue'),
      //   meta: {
      //     title: 'Holitime - Showcase',
      //   },
      // },
      {
        path: '/link-connect',
        name: 'LinkConnect',
        component: () => import('@/views/LinkConnect.vue'),
        meta: {
          title: 'Holitime - link-connect',
        },
      },
      {
        path: '/email-verify',
        name: 'EmailVerify',
        component: () => import('@/views/EmailVerify.vue'),
        meta: {
          title: 'Holitime - Email Verify',
        },
      },

      {
        path: '/subscription',
        name: 'Subscription',
        component: () => import('@/views/Subscription.vue'),
        meta: {
          title: 'Holitime - Souscription',
        },
      },
      {
        path: '/shedule-appointment/:id',
        name: 'ScheduleAppointment',
        component: () => import('@/views/ScheduleAppointment.vue'),
        meta: {
          title: 'Holitime - Calendrier',
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.step) {
            next({ ...to, query: { ...to.query, step: '2' } })
          } else {
            next()
          }
        },
      },
      {
        path: '/:discipline/:city/:practitionerSlug',
        name: 'PractitionerPublicProfile',
        component: () => import('@/views/PractitionerPublicProfile.vue'),
        meta: {
          title: 'Holitime',
          ogTitle: '',
          ogDescription: ``,
          ogType: 'profile',
          ogUrl: '',
          ogImage: '',
          ogSite_name: 'Holitime',
          ogLocale: 'fr_FR',
        },
        beforeEnter: async (to, from, next) => {
          const practitionerPublicProfileStore =
            usePractitionerPublicProfileStore()
          await practitionerPublicProfileStore.fetchProfile(
            to.params.practitionerSlug,
            to.params.discipline,
          )
          if (practitionerPublicProfileStore.profile === null) {
            await next()
          }
          const practitionerName =
            practitionerPublicProfileStore.profile.owner.fullName

          const discipline =
            practitionerPublicProfileStore.profile.mainDiscipline.name

          const city =
            practitionerPublicProfileStore.profile?.profileAddresses[0]?.address
              ?.city || null

          const ogTitle = `${practitionerName} - ${discipline} - ${city} - Holitime`
          const ogUrl = window.location.origin + to.path
          const ogImage =
            practitionerPublicProfileStore.profile.avatar?.url ?? 'none'
          const ogDescription = `
            Prenez rendez-vous avec
            ${practitionerName} : ${practitionerPublicProfileStore.profile.tagline}`

          const ogImageWidth = '1200'
          const ogImageHeight = '630'
          const ogProfileFirst_name =
            practitionerPublicProfileStore.profile.owner.firstName ??
            practitionerPublicProfileStore.profile.owner.fullName.split(' ')[0]
          const ogProfileLast_name =
            practitionerPublicProfileStore.profile.owner.lastName ??
            practitionerPublicProfileStore.profile.owner.fullName.split(' ')[1]

          document.title = ogTitle
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute('content', ogTitle)
          document
            .querySelector('meta[property="og:description"]')
            .setAttribute('content', ogDescription)
          document
            .querySelector('meta[property="og:url"]')
            .setAttribute('content', ogUrl)
          document
            .querySelector('meta[property="og:image"]')
            .setAttribute('content', ogImage)

          const referenceElement = document.querySelector(
            'meta[property="og:locale"]',
          )
          insertOgMeta(
            'og:profile:username',
            to.params.practitionerSlug,
            referenceElement,
          )
          insertOgMeta(
            'og:profile:last_name',
            ogProfileLast_name,
            referenceElement,
          )
          insertOgMeta(
            'og:profile:first_name',
            ogProfileFirst_name,
            referenceElement,
          )
          insertOgMeta('og:image:height', ogImageHeight, referenceElement)
          insertOgMeta('og:image:width', ogImageWidth, referenceElement)

          // set og type meta field
          document
            .querySelector('meta[property="og:type"]')
            .setAttribute('content', 'profile')

          await next()
        },
      },
      {
        path: '/search',
        name: 'SearchPage',
        component: () => import('@/views/SearchPage.vue'),
        meta: {
          title: 'Holitime - Recherche',
        },
      },
      {
        path: '/:disciplineSlug',
        name: 'DisciplineSearchPage',
        component: () => import('@/views/SearchPage.vue'),
        meta: {
          title: 'Holitime',
        },
        beforeEnter: (to, from, next) => {
          const title = to.meta.title.replace(
            ':disciplineSlug',
            to.params.disciplineSlug,
          )
          document.title = title + ' - ' + to.params.disciplineSlug
          next()
        },
      },
      {
        path: '/:disciplineSlug/:city',
        name: 'DisciplineCitySearchPage',
        component: () => import('@/views/SearchPage.vue'),
        meta: {
          title: 'Holitime',
        },
        beforeEnter: (to, from, next) => {
          const discipline = capitalizeFirstLetter(to.params.disciplineSlug)
          const city = capitalizeFirstLetter(to.params.city)
          const title = `${discipline} - ${city} | Prendre rendez-vous sur Holitime`
          const description = `Prenez rendez-vous avec un praticien de ${discipline} à ${city} sur Holitime`
          document.title = title
          // og tags
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute('content', title)
          document
            .querySelector('meta[property="og:description"]')
            .setAttribute('content', description)
          next()
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', description)
        },
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/PageNotFound.vue'),
        meta: {
          title: 'Holitime - 404',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {
          ogTitle: 'Espace Praticien/client - Connexion Sécurisée',
          ogDescription: `Utilisez notre outil de recherche avancée
          pour trouver le praticien qui correspond parfaitement
          à vos besoins. Consultez les profils détaillés,
          lisez les avis des patients et
          prenez rendez-vous en quelques clics.`,
          title: 'Holitime - Connexion',
        },
      },
      {
        path: '/login-with-link',
        name: 'LoginWithLink',
        component: () => import('@/views/LoginWithLink.vue'),
        meta: {
          ogTitle: 'Espace Praticien/client - Connexion Sécurisée',
          ogDescription: `Utilisez notre outil de recherche avancée
          pour trouver le praticien qui correspond parfaitement
          à vos besoins. Consultez les profils détaillés,
          lisez les avis des patients et
          prenez rendez-vous en quelques clics.`,
          title: 'Holitime - Connexion',
        },
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register.vue'),
        meta: {
          title: 'Holitime - Inscription',
        },
        beforeEnter: (to, from, next) => {
          if (!to.query.role) {
            next({ ...to, query: { ...to.query, role: 'praticien' } })
          } else {
            next()
          }
        },
      },
      {
        path: '/change-password-request',
        name: 'RequestPassword',
        component: () => import('@/views/ForgotPasswordRequest.vue'),
        meta: {
          title: 'Holitime - Password',
        },
      },
      {
        path: '/confirmation',
        name: 'Confirmation',
        component: () => import('@/views/RegisterConfirmation.vue'),
        meta: {
          title: 'Holitime - Password',
        },
      },
    ],
  },
  {
    path: '/progress',
    component: () => import('@/layouts/RegisterProgress.vue'),
    children: [
      {
        path: 'reminder-pack',
        name: 'ReminderPack',
        component: () => import('@/views/register-progress/ReminderPack.vue'),
        meta: {
          title: 'Holitime - Subscription Lists',
          name: 'Lists',
        },
        beforeEnter: (to, from, next) => {
          if (
            useUserStore().user.profilType === 'practitioner' &&
            !useUserStore().user.isSignUpIncomplete
          ) {
            next({ name: 'DashboardChangeSubscription' })
          } else {
            next()
          }
        },
      },
      {
        path: 'step2',
        name: 'StepTwo',
        component: () => import('@/views/register-progress/StepTwo.vue'),
        meta: {
          title: 'Holitime - Subscription Step 2',
          name: 'Subscription Step 2',
        },
        beforeEnter: (to, from, next) => {
          if (
            selectedSubscription.value &&
            !selectedSubscription.value.price &&
            useUserStore().user.isSignUpIncomplete
          ) {
            next({ name: 'ReminderPack' })
          } else if (!to.query.nextStep) {
            next({ ...to, query: { ...to.query, nextStep: 'subscription' } })
          } else {
            if (!useUserStore().user.isSignUpIncomplete) {
              next({ name: 'DashboardSubscription' })
            } else {
              next()
            }
          }
        },
      },
      {
        path: 'change-subscription',
        name: 'DashboardChangeSubscription',
        component: () =>
          import('@/views/dashboard/subscription/ChangeSubscription.vue'),
        meta: {
          title: "Holitime - Changement d'Abonnement",
          name: "Changement d'abonnement",
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { top: 0 }
    }
  },
})

const refSlug = useStorage('ref-slug', null)
router.afterEach(to => {
  // scroll to top
  if (!to.hash) {
    window.scrollTo(0, 0)
  }

  if (to.query.ref) {
    refSlug.value = Array.isArray(to.query.ref) ? to.query.ref[0] : to.query.ref
  }
})

router.beforeEach((to, from, next) => {
  const global = useAppStore()
  global.pageNotFound = false
  document.title = `${to.meta.title}`
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute(
      'content',
      `${to.meta.ogTitle ? to.meta.ogTitle : 'Holitime - Votre Portail vers le Bien-être Holistique et Naturel'}`,
    )
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute(
      'content',
      `${to.meta.ogDescription ? to.meta.ogDescription : 'La plateforme dédiée aux pratiques de bien-être naturelles et holistiques'}`,
    )

  if (to.matched.length === 0) {
    global.pageNotFound = true
    next({ name: '404' })
    return
  }

  const userStore = useUserStore()
  const affiliateDashboardPage = /^\/affiliation/
  const isAffiliationPage = affiliateDashboardPage.test(to.path)
  if (isAffiliationPage && !userStore.isLogged) {
    next({ name: 'Partners' })
    return
  }

  const securedPages = /^\/(dashboard|onboarding|subscription|progress)/
  const authRequired = securedPages.test(to.path)
  const dashboardPages = /^\/dashboard/

  if (
    to.name === 'Register' &&
    to.query.role === 'praticien' &&
    to.query.step === '1' &&
    userStore.isLogged
  ) {
    next({ name: 'DashboardAccount' })
    return
  }

  if (to.name === 'Login' && userStore.isLogged) {
    next({ name: 'DashboardAccount' })
    return
  }

  if (authRequired && !userStore.isLogged) {
    next({ name: 'Login', query: { redirectUrl: to.fullPath } })
    return
  }

  if (
    dashboardPages.test(to.path) &&
    to.name !== 'ForgotPassword' &&
    to.name !== 'EmailVerify' &&
    userStore.user.profilType === 'practitioner' &&
    userStore.user.isSignUpIncomplete
  ) {
    if (selectedSubscription.value && !selectedSubscription.value?.price) {
      next({ name: 'ReminderPack' })
      return
    } else {
      next({ name: 'StepTwo', query: { nextStep: 'subscription' } })
      return
    }
  }

  if (
    dashboardPages.test(to.path) &&
    (global.navRole === 'patient' || userStore.user.profilType === 'patient') &&
    (to.name === 'DashboardHome' ||
      to.name === 'DashboardAddress' ||
      to.name === 'DashboardTimeSlot' ||
      to.name === 'DashboardAppointment' ||
      to.name === 'DashboardProfile' ||
      to.name === 'DashboardLegalSupport' ||
      to.name === 'DashboardSubscription')
  ) {
    next({ name: 'DashboardAppointmentsPatients' })
    return
  } else {
    next()
  }
})

router.afterEach(() => {
  LoadingBackdropService.stop()
})

export default router
