<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useStripe } from '@/shared/payment'
import { useDisplay, useTheme } from 'vuetify'
import { useUserStore } from '@/store/user/userStore'

const userStore = useUserStore()

const { displayLabel, loading } = defineProps({
  displayLabel: {
    type: Boolean,
    default: false,
  },
  loading: Boolean,
})

const {
  stripe,
  stripeError,
  stripeCard,
  stripeCardExpiry,
  stripeCardCvc,
  initStripe,
} = useStripe(useTheme())

const fullname = ref('')

onMounted(() => {
  initStripe()
})

// create payment methode id
const paymentMethod = async () => {
  try {
    const result = await stripe.value.createPaymentMethod({
      type: 'card',
      card: stripeCard.value,
      billing_details: {
        email: userStore.user.email,
        name: fullname.value,
      },
    })

    if (result.error) {
      return { status: false, error: result.error.message }
    } else {
      return { status: true, paymentMethodId: result.paymentMethod.id }
    }
  } catch (_) {
    return {
      status: false,
      error: 'Une erreur est survenue',
      paymentMethodId: null,
    }
  }
}

// permet de stocker la carte pour une utilisation futur
const confirmCardSetup = async (clientSecret: string, pm_id: string) => {
  try {
    const result = await stripe.value.confirmCardSetup(clientSecret, {
      payment_method: pm_id,
    })

    if (result.error) {
      return { status: false, error: result.error.message }
    } else {
      return { status: true, error: '' }
    }
  } catch (_) {
    return { status: false, error: 'Une erreur est survenue' }
  }
}

// débite immédiatement
const confirmCardPayment = async (clientSecret: string, pm_id: string) => {
  try {
    const result = await stripe.value.confirmCardPayment(clientSecret, {
      payment_method: pm_id,
    })

    if (result.error) {
      return { status: false, error: result.error.message }
    } else {
      return { status: true, error: '' }
    }
  } catch (_) {
    return { status: false, error: 'Une erreur est survenue' }
  }
}

const fieldFocus = field => {
  setTimeout(() => {
    field.focus()
  }, 10)
}

defineExpose({
  paymentMethod,
  confirmCardPayment,
  confirmCardSetup,
})
</script>

<template>
  <div class="">
    <div v-if="displayLabel">
      <label class="">Information bancaire</label>
      <v-text-field
        class="mt-2"
        rounded="lg"
        density="compact"
        :readonly="loading"
        placeholder="Nom sur la carte"
        required
        type="text"
        bg-color="white"
        v-model="fullname"
      />
    </div>
    <v-card color="white" elevation="0" border>
      <div class="px-3 py-2">
        <card-input
          @update:focused="fieldFocus(stripeCard)"
          :error-messages="stripeError"
          :readonly="loading"
        >
          <div id="card-element">Elements will create input elements here</div>
        </card-input>
      </div>
      <v-divider />
      <v-card-text border class="pa-0">
        <div class="d-sm-flex">
          <div class="w-sm-50 border-custom pa-3">
            <card-input
              placeholder="MM / AA"
              @update:focused="fieldFocus(stripeCardExpiry)"
              hide-details
              :readonly="loading"
            >
              <div id="card-expiry">
                Elements will create input elements here
              </div>
            </card-input>
          </div>
          <div class="w-sm-50 pa-3">
            <card-input
              placeholder="***"
              @update:focused="fieldFocus(stripeCardCvc)"
              hide-details
              :readonly="loading"
            >
              <div id="card-cvc" style="width: 100%">
                Elements will create input elements here
              </div>
            </card-input>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
.border-custom {
  border-right: 1px solid #b2aeae;
}

@media (max-width: 599px) {
  .border-custom {
    border-right: none;
    border-bottom: 1px solid #b2aeae;
  }
}
</style>
