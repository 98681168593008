<script lang="ts" setup>
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import ClosexIcon from '../icons/ClosexIcon.vue'
import { ProfileInformationData } from '@/api/profile/profile.d'
import PractitionerAvatar from '@/components/PractitionerAvatar.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'

const { t } = useI18n()

const { dialog, profiles, currentProfileId } = defineProps({
  dialog: Boolean,
  profiles: Object as PropType<ProfileInformationData[]>,
  currentProfileId: String,
})

const emit = defineEmits([
  'update:close',
  'update:selected',
  'update:delete',
  'update:draft',
])
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title class="d-flex justify-end w-full align-center bg-white">
        <ClosexIcon @click="emit('update:close')" class="cursor-pointer" />
      </v-card-title>

      <v-card-text class="px-5 pt-0">
        <div class="d-flex flex-column ga-3">
          <p>{{ t('dashboard.profile.select_profil') }}</p>

          <template v-for="(profil, idx) in profiles">
            <div
              v-if="profil.id"
              @click="
                () => emit('update:selected', { profile: profil, index: idx })
              "
              class="cursor-pointer rounded-lg profile-card d-flex align-center justify-space-between pa-1"
              style="background-color: #e0e0e0"
              :class="{
                'border-md border-teal border-opacity-100':
                  profil.id === currentProfileId,
              }"
            >
              <div class="d-flex align-center ga-2">
                <v-avatar size="40" class="border-md">
                  <PractitionerAvatar
                    width="40"
                    height="40"
                    v-show="!profil.avatar"
                    color="#9E9E9E"
                  />
                  <v-img
                    v-show="profil.avatar"
                    :src="profil.avatar?.url"
                    :cover="true"
                  ></v-img>
                </v-avatar>
                <div>
                  <div style="font-size: 16px">
                    {{ profil.mainDiscipline.name }}
                  </div>
                  <div
                    :class="{
                      'text-blue': profil.draft?.isSynced,
                      'text-teal': !profil.draft?.isSynced,
                    }"
                    style="font-weight: 600; font-size: 12px"
                  >
                    {{
                      profil.draft.isSynced
                        ? $t('accountForm.save-and-publish')
                        : $t('accountForm.not-synced')
                    }}
                  </div>
                </div>
              </div>

              <v-menu offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon="mdi-dots-horizontal"
                    variant="text"
                    v-bind="props"
                  ></v-btn>
                </template>
                <v-list
                  base-color="#757575"
                  class="mt-2 pa-2"
                  bg-color="white"
                  rounded="lg"
                  density="compact"
                  nav
                >
                  <v-list-item
                    class="menu-item"
                    v-if="profil.profileAddresses[0]"
                    :to="{
                      name: 'PractitionerPublicProfile',
                      params: {
                        discipline: profil.mainDiscipline.slug,
                        city: profil.profileAddresses[0]?.address?.city,
                        practitionerSlug: profil.slug,
                      },
                    }"
                    target="_blank"
                  >
                    <v-list-item-title class="d-flex align-center ga-1">
                      <v-icon color="#9E9E9E">mdi-eye-outline</v-icon>
                      <span>{{ $t('dashboard.profile.see_as_patient') }}</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="() => emit('update:draft', { id: profil.id })"
                    class="menu-item"
                  >
                    <v-list-item-title class="d-flex align-center ga-1">
                      <v-icon color="#9E9E9E">mdi-content-copy</v-icon>
                      <span>{{ $t('dashboard.profile.copy_in_draft') }}</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="
                      () =>
                        emit('update:selected', { profile: profil, index: idx })
                    "
                    class="menu-item"
                  >
                    <v-list-item-title class="d-flex align-center ga-1">
                      <PencilIcon color="#757575" size="24" />
                      <span>{{ $t('dashboard.profile.update_profile') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider />

                  <v-list-item
                    @click="
                      emit('update:delete', { profile: profil, idx: idx })
                    "
                    class="menu-item"
                  >
                    <v-list-item-title class="d-flex align-center ga-1">
                      <TrashIcon color="#F56565" />
                      <span style="color: #f56565">{{
                        $t('accountForm.avatar.deleteProfil')
                      }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <div
              v-else
              @click="
                () => emit('update:selected', { profile: profil, index: idx })
              "
              class="cursor-pointer rounded-lg profile-card d-flex align-center justify-space-between pa-1"
              style="background-color: #e0e0e0"
              :class="{
                'border-md border-bright-orange border-opacity-100':
                  profil.draft.id === currentProfileId,
              }"
            >
              <div class="d-flex align-center ga-2">
                <v-avatar size="40" class="border-md">
                  <PractitionerAvatar
                    width="40"
                    height="40"
                    v-show="!profil.avatar"
                    color="#9E9E9E"
                  />
                  <v-img
                    v-show="profil.avatar"
                    :src="profil.avatar?.url"
                    :cover="true"
                  ></v-img>
                </v-avatar>
                <div>
                  <div class="text-bright-orange" style="font-size: 16px">
                    {{
                      profil.draft.mainDiscipline.name ??
                      t('dashboard.profile.unPublish')
                    }}
                  </div>
                  <div
                    class="text-bright-orange"
                    style="font-weight: 600; font-size: 12px"
                  >
                    {{ $t('accountForm.not-publish') }}
                  </div>
                </div>
              </div>

              <v-menu offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon="mdi-dots-horizontal"
                    variant="text"
                    v-bind="props"
                  ></v-btn>
                </template>
                <v-list
                  base-color="#757575"
                  class="mt-2 pa-2"
                  bg-color="white"
                  rounded="lg"
                  density="compact"
                  nav
                >
                  <v-list-item
                    @click="
                      () =>
                        emit('update:selected', { profile: profil, index: idx })
                    "
                    class="menu-item"
                  >
                    <v-list-item-title class="d-flex align-center ga-1">
                      <PencilIcon color="#757575" size="24" />
                      <span>{{ $t('dashboard.profile.update_profile') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider />

                  <v-list-item
                    @click="
                      emit('update:delete', { profile: profil, idx: idx })
                    "
                    class="menu-item"
                  >
                    <v-list-item-title class="d-flex align-center ga-1">
                      <TrashIcon color="#F56565" />
                      <span style="color: #f56565">{{
                        $t('accountForm.avatar.deleteProfil')
                      }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.alert-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #757575;
}

@media (max-width: 599px) {
  .title {
    font-size: 14px;
  }
}
</style>
