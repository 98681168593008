<template>
  <v-dialog v-model="dialog" max-width="550">
    <v-card class="bg-white">
      <v-card-title
        class="pa-2 justify-center w-full d-flex align-center bg-surface-lighten-1 text-primary"
      >
        <div class="text-center">
          {{ t('dashboard.appointment.dialogDetails.title') }}
        </div>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="dialog = false"
          src="@/assets/_close.svg"
        />
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text
        class="d-flex overflow-scroll flex-column ga-2 details-dialog-text"
      >
        <div class="d-flex justify-center">
          <div class="d-flex flex-column justify-center ga-5">
            <div class="text-center">
              <v-avatar
                size="101"
                class="border-md border-secondary border-opacity-100"
              >
                <img
                  v-if="valueCalendar?.patient?.avatar"
                  :src="
                    typeof valueCalendar?.patient?.avatar === 'string'
                      ? valueCalendar?.patient?.avatar
                      : valueCalendar?.patient?.avatar?.url
                  "
                  alt="photo de profil"
                  style="width: 101px; height: 101px"
                />
                <PractitionerAvatar v-else color="#9E9E9E" />
              </v-avatar>
              <div class="d-flex flex-column text-center">
                <div
                  class="patient-name d-flex align-center ga-1 justify-center"
                >
                  <span>{{ valueCalendar?.patient?.fullName }}</span>
                  <GoogleIcon
                    v-if="valueCalendar.event_type == 'synced_from_google'"
                    :size="18"
                  />
                </div>

                <div
                  class="d-flex align-center ga-2"
                  v-if="valueCalendar.patient?.email"
                >
                  <div class="d-flex align-center ga-2">
                    <EnvelopeIcon :size="15" />
                    <span class="text-primary"
                      >{{
                        t('dashboard.appointment.dialogDetails.mail')
                      }}:</span
                    >
                  </div>
                  <span class="text-gray-80">{{
                    valueCalendar.patient?.email
                  }}</span>
                </div>

                <div class="d-flex align-center ga-2" v-if="!!formatPhone">
                  <div class="d-flex align-center ga-2">
                    <PhoneIcon :size="15" />
                    <span class="text-primary"
                      >{{
                        t('dashboard.appointment.dialogDetails.phone')
                      }}:</span
                    >
                  </div>
                  <span class="text-gray-80">{{ formatPhone }}</span>
                </div>

                <div v-if="meetingLink">
                  <v-icon color="primary">mdi-video</v-icon>
                  <a :href="meetingLink" target="_blank">{{ meetingLink }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="d-flex flex-column text-center ga-3">
            <div class="d-flex justify-center ga-1 appointment-date">
              {{ t('dashboard.appointment.dialogDetails.appointmentDate') }}
            </div>
            <div class="startDate rounded-xl py-1 text-center">
              <span>{{ startEndDate }}</span>
            </div>

            <div style="font-size: 13px; font-weight: 500; color: #616161">
              {{
                `Consultation - ${valueCalendar.acceptRemote === 'in-person' ? `${t('dashboard.appointment.dialogDetails.presentiel')}` : `${t('dashboard.appointment.dialogDetails.remote')}`}`
              }}
            </div>
          </div>
        </div>

        <template v-if="description">
          <div class="justify-center text-center">
            <div class="detail-title">
              {{ t('dashboard.appointment.dialogDetails.description') }}
            </div>
            <span v-html="description"></span>
          </div>
        </template>

        <div
          class="detail-title cursor-pointer"
          @click="displayHistory = !displayHistory"
        >
          <span>{{
            t('dashboard.appointment.dialogDetails.historyTitle')
          }}</span>
          <v-icon>{{
            displayHistory ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </div>
        <v-btn
          v-if="loadingHistory"
          variant="text"
          :loading="loadingHistory"
        ></v-btn>

        <div
          class="mt-2 rounded-xl pa-2"
          style="background: #f5f5f5"
          v-if="histories.length && displayHistory"
        >
          <template v-for="(history, index) in histories" :key="index">
            <div class="py-1">
              <div class="d-flex justify-space-between py-1">
                <span class="text-primary font-weight-bold">{{
                  dayjs(history.startDateTime).format('D MMMM YYYY')
                }}</span>
                <span class="" style="font-weight: 400; color: #616161"
                  >{{
                    history.presential
                      ? `${t('dashboard.appointment.dialogDetails.presentiel')}`
                      : `${t('dashboard.appointment.dialogDetails.presentiel')}`
                  }}
                </span>
              </div>
              <v-divider class="mt-1" v-if="index < histories.length - 1" />
            </div>
          </template>
        </div>

        <p
          class="text-center text-gray-80 font-italic"
          v-if="!histories.length && !loadingHistory"
        >
          {{ t('dashboard.appointment.dialogDetails.noHistory') }}
        </p>

        <template v-if="!isInPast">
          <div class="d-sm-flex ga-2 mt-2">
            <v-btn
              @click="displayAlert = true"
              :disabled="event_type == 'synced_from_google'"
              color="red"
              class="w-sm-50 w-100 mb-2 mb-sm-0"
              variant="outlined"
            >
              {{
                t('dashboard.appointment.dialogDetails.cancelTheAppointment')
              }}
            </v-btn>
            <v-btn
              @click="openModifyConfirm"
              color="secondary"
              :disabled="event_type == 'synced_from_google'"
              class="w-sm-50 w-100"
            >
              {{
                t('dashboard.appointment.dialogDetails.modifyTheAppointment')
              }}
            </v-btn>
          </div>
        </template>

        <template v-if="isInPast">
          <div class="d-sm-flex ga-2 mt-2">
            <v-btn
              color="red"
              @click="() => confirmPatientAbsence()"
              class="w-sm-50 w-100"
              variant="outlined"
            >
              {{ t('dashboard.appointment.dialogDetails.btnAbsent') }}
            </v-btn>
            <v-btn
              @click="() => confirmPatientPresence()"
              color="secondary"
              class="w-sm-50 w-100 mb-2 mb-sm-0"
            >
              {{ t('dashboard.appointment.dialogDetails.btnPresent') }}
            </v-btn>
          </div>
        </template>
      </v-card-text>
    </v-card>
    <AlertDialog
      v-if="displayAlert"
      :dialog="displayAlert"
      :minTitle="
        t('dashboard.appointment.dialogDetails.alertCancelAppointment')
      "
      :title="t('dashboard.appointment.dialogDetails.cancelConfirmation')"
      :description="t('dashboard.appointment.dialogDetails.cancelDescription')"
      @update:cancel="displayAlert = false"
      @update:confirm="() => handleCancelAppointment()"
    />
  </v-dialog>
</template>

<script lang="ts" setup>
import { computed, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'
import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import MessageService from '../feedback/message/messageService'
import EventBus from '@/utils/eventBus'
import {
  PatientAugmentedData,
  PractitionerAppointmentEvent,
  PatientDataForCalendar,
  PractitionerAppointmentInformationData,
} from '@/store/practitioner-appointment/practitionerAppointmentEvent'
import { useDialogPractitionerAppointmentStore } from '@/store/practitioner-appointment/dialogAppointmentStore'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { AxiosResponse } from 'axios'
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'
import { useUserStore } from '@/store/user/userStore'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import PractitionerAvatar from '@/components/PractitionerAvatar.vue'
import parsePhoneNumber from 'libphonenumber-js'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'
import EnvelopeIcon from '@/components/icons/EnvelopeIcon.vue'
import DurationIcon from '@/components/icons/DurationIcon.vue'
import GoogleIcon from '../icons/GoogleIcon.vue'
import AlertDialog from '../dialog/AlertDialog.vue'

const { mdAndUp } = useDisplay()
dayjs.extend(utc)
const httpClient = createHttpClient()
const { t } = useI18n()
const displayAlert = ref(false)
const userStore = useUserStore()

const dialog = ref(false)
const selectedDate = ref(null)
const description = ref('')
const cancelationId = ref('')
const event_type = ref('')
const meetingLink = ref('')

const patient: Ref<PatientAugmentedData | PatientDataForCalendar> = ref()
const valueCalendar: Ref<PractitionerAppointmentEvent> = ref()
const loadingHistory = ref(false)
const histories = ref<PractitionerAppointmentInformationData[]>([])

const getPatientHistory = async () => {
  if (loadingHistory.value) return false
  histories.value = []
  loadingHistory.value = true
  const patientId = patient.value.id
  const practitionnerId = userStore.user.id
  try {
    const appointmentsResponse: AxiosResponse<
      PractitionerAppointmentInformationData[]
    > = await httpClient.get(
      `/appointment/${practitionnerId}/patient/${patientId}`,
    )

    histories.value = appointmentsResponse.data
  } catch (_) {
  } finally {
    loadingHistory.value = false
  }
}

const startEndDate = computed(() => {
  const startDate = valueCalendar.value.start
  const endDate = valueCalendar.value.end

  return `${dayjs(startDate).format('D MMMM YYYY')} de ${dayjs(startDate).format('HH:mm')} à ${dayjs(endDate).format('HH:mm')}`
})

const formatPhone = computed(() => {
  if (valueCalendar.value.patient && valueCalendar.value.patient.phoneNumber) {
    return parsePhoneNumber(
      valueCalendar.value.patient.phoneNumber,
    ).formatInternational()
  }
})

const confirmPatientPresence = async () => {
  LoadingBackdropService.start()
  try {
    await httpClient.post(
      `/appointment-notification/${valueCalendar.value.id}/confirmed`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dialog.value = false
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
  }
  LoadingBackdropService.stop()
}

const confirmPatientAbsence = async () => {
  LoadingBackdropService.start()
  try {
    await httpClient.post(
      `/appointment-notification/${valueCalendar.value.id}/confirmed`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dialog.value = false
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
    console.error(error)
  }
  LoadingBackdropService.stop()
}

const dateAppointment = ref<dayjs.Dayjs>(null)
const isInPast = computed(() => {
  return dateAppointment.value.isBefore(dayjs())
})
EventBus.on(
  'openDetailsAppointmentWithEvent',
  async (calendarEvent: PractitionerAppointmentEvent) => {
    valueCalendar.value = calendarEvent
    patient.value = calendarEvent.patient as PatientDataForCalendar
    dialog.value = true

    event_type.value = calendarEvent.event_type
      ? calendarEvent.event_type
      : 'native'

    meetingLink.value = calendarEvent.meetingLink
      ? calendarEvent.meetingLink
      : ''

    description.value = calendarEvent.description
      ? calendarEvent.description
      : ''

    dateAppointment.value = dayjs(calendarEvent.start, 'YYYY-MM-DD HH:mm')

    try {
      cancelationId.value = calendarEvent.id

      // description.value = calendarEvent.description ?? "Non renseigné"
      if (event_type.value == 'native') {
        await getPatientHistory()
      } else {
        history.value = []
      }
    } catch (e) {
      console.error(e)
    }
  },
)
EventBus.on('close-dialog-details', async () => {
  dialog.value = false
  history.value = []
})
// I keep comments to see an example of the data we have to put
const items = ref([
  {
    name: 'Ex: Thomas Pascal',
    icon: 'mdi-account-multiple',
    key_name: 'name',
    event_type: 'native',
  },
  {
    name: 'Ex: Jeudi 11 avril de 7h00 à 9h00',
    date: '11 avril',
    hour_from: '9h00',
    hour_to: '9h00',
    key_name: 'date_time',
    icon: 'mdi-clock',
  },
  { name: 'Ex: 19 Rue Charles de Gaulle. 75006 Paris', icon: 'mdi-map-marker' },
  {
    name: 'Ex: Motif de consultation 1. Motif de consultation 2',
    icon: 'mdi-information',
  },
  { name: 'email', icon: 'mdi-at' }, // email
  { name: 'phone', icon: 'mdi-phone' }, // phone number
])

const history = ref([])

const openModifyConfirm = () => {
  const { toggleDialogModify } = useDialogPractitionerAppointmentStore()
  toggleDialogModify(valueCalendar)
}

const handleCancelAppointment = async () => {
  LoadingBackdropService.start()
  try {
    const deleteResponse = await httpClient.delete(
      '/appointment/' + cancelationId.value,
    )
    if (deleteResponse.status !== 200) {
      throw Error(JSON.stringify(deleteResponse))
    }
    MessageService.success(
      t('dashboard.appointment.dialogDetails.successCancelAppointment'),
    )
    const practitionerAppointmentStore = usePractitionerAppointmentStore()
    await practitionerAppointmentStore.initAppointments()
    dialog.value = false
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
  } finally {
    displayAlert.value = false
  }
  LoadingBackdropService.stop()
}

const displayHistory = ref(false)
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
  top: 10px;
}
.highlighted {
  font-size: 20px;
  font-weight: bold;
  opacity: 1;
}
.v-list.v-theme--holitimeLightTheme.v-list--density-default.v-list--one-line {
  overflow: hidden;
  background: #fff;
}
.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #efe8e8;
  padding: 5px 40px;
  box-shadow: 0px 0px 2px rgb(177 166 166 / 50%);
  height: auto;
  border-radius: 999px;
  background: #f8f8f8;
  color: #1973e9;
  font-weight: bold;
  opacity: 0.7;
  cursor: pointer;
  width: 200px;
  justify-content: center;
}
.highlighted .date {
  opacity: 1;
}

.v-list-item__content {
  display: flex;
  align-items: center;
}

.date-day {
  display: block;
  font-size: 35px;
  font-weight: 900;
  margin-top: 5px;
  line-height: 0.9;
}
.date-month {
  font-size: 14px;
}
.date-year {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: darkgrey;
}
.center-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-list-tile-content {
  color: gray;
}
.underline-link {
  text-decoration: underline;
  color: #337dea;
  font-weight: 500;
}
.v-dialog > .v-overlay__content > .v-card {
  overflow-y: hidden;
}
.list {
  overflow-y: auto !important;
  height: 300px;
}
.overflow-scroll {
  overflow-y: auto;
}
@media only screen and (max-width: 600px) {
  .v-dialog > .v-overlay__content > .v-card {
    overflow-y: auto;
  }
  .close-icon {
    top: 0;
  }
  .v-card-text.d-flex.overflow-scroll.flex-column.ga-2.details-dialog-text {
    padding: 3px;
  }
  .custom-title-details {
    font-size: 12px;
  }
  .v-card-actions.modify-details-button.flex-wrap[data-v-ba18376d] {
    gap: 0;
    padding: 0;
    margin: -15px;
  }
}
.v-list-item-title {
  white-space: unset;
}

.appointment-google-icon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.startDate {
  background-color: #eafffa;
}

.startDate span {
  color: #007058;
  font-weight: 500;
  font-size: 14px;
}

.detail-title {
  font-size: 14px;
  font-weight: 600;
  color: #757575;
  text-align: center;
}

.patient-name {
  color: #424242;
  font-size: 19px;
  font-weight: 700;
}
.appointment-date {
  font-weight: 700;
  font-size: 16px;
  color: #007058;
}
</style>
