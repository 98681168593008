<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const isFocused = ref(false)
const isHovered = ref(false)

interface billingAddress {
  addressCompany: string
  postalCodeCompany: string
  cityCompany: string
}

const model = defineModel<billingAddress>('modelValue', {
  default: { addressCompany: '', postalCodeCompany: '', cityCompany: '' },
})

const payload = computed(
  () =>
    model.value || {
      addressCompany: '',
      postalCodeCompany: '',
      cityCompany: '',
    },
)

const { loading } = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <v-form class="d-flex ga-2 flex-column">
    <label class="billing-title">{{ t('register.billing_address') }}</label>
    <v-card color="white" class="border-card" elevation="0">
      <v-card-text class="pa-0">
        <div class="pa-3">
          <input
            class="pa-0 no-focus w-100"
            :placeholder="$t('register.billing_address_placeholder')"
            :readonly="loading"
            v-model="payload.addressCompany"
          />
        </div>
        <v-divider color="#b2aeae" opacity="2" />
        <div class="d-sm-flex address">
          <div class="w-sm-50 border-custom pa-3">
            <input
              class="pa-0 no-focus w-100"
              :placeholder="$t('register.postal_code')"
              :readonly="loading"
              v-model="payload.postalCodeCompany"
            />
          </div>
          <div class="w-sm-50 pa-3">
            <input
              class="pa-0 no-focus w-100"
              :placeholder="$t('register.city')"
              :readonly="loading"
              v-model="payload.cityCompany"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<style scoped lang="scss">
.no-focus:focus {
  outline: none;
  box-shadow: none;
}
.border-custom {
  border-right: 1px solid #b2aeae;
}

.border-card {
  border: 1.5px solid #b2aeae;
}

.address input {
  width: 217px !important;
}

.border-focused {
  border-color: #3b3b3b !important;
  border: 1.8px solid #3b3b3b;
}

.billing-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  color: #616161;
  letter-spacing: -0.64;
}

@media (max-width: 599px) {
  .border-custom {
    border-right: none;
    border-bottom: 1px solid #b2aeae;
  }
}
</style>
