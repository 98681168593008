<script setup lang="ts">
import { onUnmounted } from 'vue'
import { defineEmits, ref, onMounted, watch, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { VDateInput } from 'vuetify/labs/VDateInput'

const props = defineProps({
  dialog: Boolean,
  loading: Boolean,
  changeAddressFrom: String,
  type: {
    type: String as () => 'edit' | 'delete' | null,
    required: true,
  },
})

const { t } = useI18n()

const appointmentAnnulation = ref(true)

const emit = defineEmits([
  'update:confirm',
  'update:close',
  'update:changeAddressFrom',
])

const modalDate = ref(null)
const today = new Date().toISOString().split('T')[0] // Date du jour
const formErrors = ref({ date: '' })

onUnmounted(() => {
  clearError('date')
})

// Nettoyer les erreurs
const clearError = field => {
  formErrors.value[field] = ''
}

// Validation du formulaire
const validateForm = () => {
  formErrors.value.date = '' // Réinitialise les erreurs

  // if (!modalDate.value) {
  //   formErrors.value.date = 'Veuillez sélectionner une date'
  //   return false
  // }
  if (modalDate.value && modalDate.value < today) {
    formErrors.value.date = 'La date ne peut pas être dans le passé'
    return false
  }
  return true
}

// Soumission du formulaire
const submitForm = (type: string) => {
  if (type === 'delete') {
    emit('update:confirm')
    return
  }
  if (validateForm()) {
    emit('update:changeAddressFrom', modalDate.value)
    emit('update:confirm')
  }
}
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="500"
    transition="dialog-top-transition"
  >
    <v-card color="white" rounded="xl">
      <v-card-title
        class="d-flex justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="title font-weight-bold">{{
          type === 'delete' ? t('address.title') : t('address.edit_title')
        }}</span>

        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="emit('update:close')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-card-text class="">
        <div class="d-flex flex-column ga-8 mx-auto">
          <v-img
            width="80"
            height="80"
            class="mx-auto d-flex align-center war-icon"
            src="@/assets/warning.png"
          />
          <div class="d-flex flex-column ga-2">
            <p
              class="text-gray-80 font-weight-bold"
              style="font-size: 16px"
              v-if="type === 'delete'"
            >
              {{ t('address.message', { value: 'supprimer' }) }}
            </p>

            <p
              v-if="(type as string) == 'delete'"
              class="text-gray-80 text-center"
              style="font-size: 13px"
            >
              {{ t('address.description') }}
              <!-- t('dashboard.addresses.alertOnUpdate') -->
            </p>

            <div
              v-if="(type as string) == 'edit'"
              class="text-gray-80 text-left"
              style="font-size: 13px"
              v-html="t('address.edit_message')"
            ></div>

            <div v-if="(type as string) == 'edit'">
              <label
                style="
                  text-align: left;
                  color: #616161;
                  display: block;
                  font-size: 14px;
                "
                >{{ $t('dashboard.addresses.chooseDate') }}</label
              >

              <v-date-input
                prepend-icon=""
                class="w-100"
                v-model="modalDate"
                :append-inner-icon="'mdi-calendar-remove'"
                :placeholder="
                  $t('dashboard.time-slot.dialogVacation.datePlaceholder')
                "
                density="compact"
                rounded="lg"
                hide-details="auto"
                hide-actions
                variant="outlined"
                :min="today"
                :error-messages="formErrors.date"
              />
            </div>
          </div>
        </div>

        <div class="d-sm-flex align-center justify-center ga-sm-2 mt-6">
          <v-btn
            style="color: #757575"
            color="#E0E0E0"
            class="w-sm-50 d-flex justify-center mb-sm-0 mb-3 w-100 font-weight-bold py-5"
            @click="emit('update:close')"
            >{{ t('dashboard.subscription.AnnulerBtn') }}</v-btn
          >
          <v-btn
            color="white"
            :loading="loading"
            class="w-sm-50 w-100 bg-secondary py-5 d-flex justify-center"
            @click="submitForm(type)"
            >{{
              type === 'delete'
                ? t('confirm-delete', { value: 'suppression' })
                : t('dashboard.addresses.edit')
            }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-size: 23px !important;
  font-weight: 600px;
  line-height: 32.2px;
}

@media (max-width: 599px) {
  .title {
    font-size: 15px !important;
  }
}
</style>
