<script setup lang="ts">
import { PropType, computed, ref, onMounted } from 'vue'
import {
  Package,
  SelectedStoreType,
} from '@/api/subscription-packages/package.d'
import CalendarXIcon from '../icons/CalendarXIcon.vue'
import HandPalmIcon from '../icons/HandPalmIcon.vue'
import { useUserStore } from '@/store/user/userStore'
import CheckednIcon from '@/components/icons/CheckednIcon.vue'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import DiamondBlackIcon from '../icons/DiamondBlackIcon.vue'
import DiamondSemiIcon from '../icons/DiamondSemiIcon.vue'
import DiamondBlueIcon from '../icons/DiamondBlueIcon.vue'
import router from '@/router'
import MessageService from '@/components/feedback/message/messageService'
import { useI18n } from 'vue-i18n'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'

import { useStorage } from '@vueuse/core'
const { t } = useI18n()

const selectedPack = useStorage('selected-pack', {} as SelectedStoreType)

const userStore = useUserStore()

const props = defineProps({
  thePackage: {
    type: Object as PropType<Package>,
    required: true,
  },
  displayAction: {
    type: Boolean,
    default: true,
  },
  displayPriceWithTva: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  displayMore: {
    type: Boolean,
    default: false,
  },
  showFeature: {
    type: Boolean,
    default: true,
  },
  classes: {
    type: String,
    default: 'rounded-b-xl border-t-0 rounded-t-0 shadow-lg',
  },
  selected_tmp_id: {
    type: String,
    default: '',
  },
  loadingPrice: {
    type: Boolean,
    default: false,
  },
})

const subscriptionStore = useSubscriptionStore()
const selectedTmpId = ref('')

const currentSubscriptionLevelId = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['subscriptionLevel']
  ) {
    const res = subscriptionStore.subscriptionStatus['subscriptionLevel']
    selectedTmpId.value = res.id
    return res.id
  }
})

const showfeatures = ref(props.showFeature)

const RegisterOrnext = () => {
  selectedPack.value = {
    id: props.thePackage.id,
    name: props.thePackage.name,
    price: props.thePackage.price,
    type: props.thePackage.type,
    category: props.thePackage.category,
  }

  if (!userStore.isLogged) {
    router.push({
      name: 'Register',
    })
  } else {
    if (
      userStore.isLogged &&
      !userStore.user.isSignUpIncomplete &&
      userStore.user.profilType === 'practitioner'
    ) {
      router.push({
        name: 'DashboardChangeSubscription',
      })
    } else {
      router.push({
        name: 'StepTwo',
        query: { nextStep: 'subscription' },
      })
    }
  }
}

const note = computed(() => {
  return Number(props.thePackage.contributionNote)
})
</script>

<template>
  <div>
    <v-card
      :disabled="disabled"
      :class="[
        'pack-card cursor-pointer',
        classes,
        {
          'border-lg border-opacity-100 border-secondary custom-shadow':
            thePackage.category === 'premium',
          'border-lg border-opacity-100 border-secondary':
            selected_tmp_id === thePackage.id,
        },
      ]"
      border="sm"
      elevation="0"
      :color="selected_tmp_id === thePackage.id ? '#eafffa' : 'white'"
    >
      <div>
        <v-img
          eager
          :width="40"
          :height="40"
          :alt="thePackage.name"
          :src="thePackage.icon?.url"
        />
        <div class="d-flex flex-column ga-0 mt-2">
          <h2 class="pack-title d-flex align-center ga-1">
            <span>{{ thePackage.name }}</span>
            <span
              v-if="currentSubscriptionLevelId === thePackage.id"
              class="d-flex align-center ga-1 rounded-pill bg-secondary selected"
            >
              <CheckednIcon color="#FFFFFF" />
              <span>{{ $t('subscriptionCard.selected') }}</span>
            </span>
          </h2>
          <span class="min-desc">{{ thePackage.contributionTitle }}</span>
        </div>

        <template v-if="note == 0">
          <div class="d-flex ga-2">
            <DiamondSemiIcon />
            <DiamondBlackIcon v-for="idx in 4" />
          </div>
        </template>

        <template v-if="note == 1">
          <div class="d-flex ga-2">
            <DiamondBlueIcon />
            <DiamondBlackIcon v-for="idx in 5 - note" />
          </div>
        </template>

        <template v-if="note == 2">
          <div class="d-flex ga-2">
            <DiamondBlueIcon v-for="idx in note" />
            <DiamondBlackIcon v-for="idx in 5 - note" />
          </div>
        </template>

        <template v-if="note == 5">
          <div class="d-flex ga-2">
            <DiamondBlueIcon v-for="idx in note" />
          </div>
        </template>
      </div>

      <div class="d-flex align-center ga-2 justify-between">
        <span class="pack-price d-flex align-center">
          <v-progress-circular
            v-if="loadingPrice && !disabled"
            :size="20"
            :witdth="5"
            indeterminate
            color="gray-80"
          />
          <span>
            {{
              displayPriceWithTva && !disabled
                ? thePackage.priceWithTva
                : thePackage?.price
            }}€
          </span>
        </span>
        <span class="price-charge">
          {{ displayPriceWithTva && !disabled ? 'TTC' : 'H.T' }}/{{
            thePackage.type === 'annually' ? 'An' : 'Mois'
          }}</span
        >
      </div>

      <template v-if="thePackage.type === 'monthly'">
        <v-divider />
        <div class="d-flex justify-space-between align-center py-1 ga-1">
          <div class="d-flex flex-column ga-1">
            <span
              class="appoint-number"
              v-if="thePackage.maxAppointmentAllowed > 0"
              >{{
                $t('subscriptionCard.rdv_count', {
                  value: thePackage.maxAppointmentAllowed,
                })
              }}</span
            >

            <span
              class="appoint-number"
              v-if="thePackage.maxAppointmentAllowed === 0"
              >{{ t('holitime_ref') }}</span
            >

            <span class="croiss-auto"
              >({{ $t('subscriptionCard.self_growth') }})</span
            >
          </div>

          <div class="d-flex ga-1 align-center">
            <span
              v-if="thePackage.maxAppointmentAllowed >= 0"
              style="
                font-weight: 700;
                color: #029978;
                font-size: 24px;
                line-height: 140%;
                letter-spacing: 0%;
              "
              >{{ thePackage.maxAppointmentAllowed }}</span
            >
            <CalendarIcon color="#007058" :size="16" />
          </div>
        </div>
        <v-divider />
      </template>

      <div class="pb-5">
        <div class="my-5">
          <!-- <div class="auto-renew mb-2">renouvellement automatique</div> -->

          <template v-if="displayAction">
            <v-btn
              class="my-2"
              block
              :variant="thePackage.category === 'premium' ? 'flat' : 'outlined'"
              color="#029978"
              :class="{
                'border border-md border-secondary border-opacity-100':
                  thePackage.category !== 'premium',
              }"
              @click="() => RegisterOrnext()"
            >
              {{
                userStore.isLogged && !userStore.user.isSignUpIncomplete
                  ? $t('subscriptionCard.change_btn')
                  : userStore.isLogged
                    ? $t('subscriptionCard.next_btn')
                    : $t('subscriptionCard.register_btn')
              }}
            </v-btn>
          </template>

          <div
            :class="{
              'border-dashed border-yellow rounded-pill text-center':
                thePackage.type === 'annually',
            }"
            :style="
              thePackage.type === 'annually'
                ? {
                    color: '#9C7E3A',
                    backgroundColor: '#FFFBF1',
                    padding: '8px 12px',
                  }
                : {}
            "
            class="pack-desc"
            v-html="thePackage.description"
          ></div>
        </div>
        <v-divider />
      </div>

      <div class="feature-list d-flex flex-column ga-2">
        <template
          v-for="(advantage, idx) in thePackage.advantages"
          v-if="showfeatures"
        >
          <div class="d-flex align-center ga-2">
            <img
              width="18"
              height="18"
              v-if="advantage.status"
              src="@/assets/auth/check_new.svg"
            />
            <img width="18" height="18" v-else src="@/assets/close.svg" />
            <span>{{ advantage.title }}</span>
          </div>
        </template>
        <div
          v-if="displayMore"
          @click="showfeatures = !showfeatures"
          class="d-flex align-center cursor-pointer d-flex justify-center text-secondary"
        >
          <span>
            {{
              showfeatures
                ? $t('subscriptionCard.less_details')
                : $t('subscriptionCard.more_details')
            }}
          </span>
          <v-icon>{{
            showfeatures ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </div>
      </div>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
.pack-card {
  padding: 40px 22px;
}

._card-title {
  background-color: #eafffa;
  border: 1px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding: 14px 24px 10px 24px;
}

.pack-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  color: #424242;
}

.min-desc {
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  color: #616161;
  letter-spacing: 0%;
}

.pack-price {
  font-size: 56px;
  color: #424242;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -2.24;
}

.price-charge {
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.8;
}

.appoint-number {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.56;
}

.croiss-auto {
  font-size: 10px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.56;
  color: #757575;
}

.feature-list {
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.26;
  color: #424242;
}

.pack-desc {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.24;
}

.auto-renew {
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  color: #616161;
}

.no-obligation {
  padding: 4px 8px;
  background-color: #f5f5f5;
  color: #616161;
  font-weight: 400;
  line-height: 140%;
  font-size: 12px;
  letter-spacing: -0.24;
}

.custom-shadow {
  box-shadow: 0px 12px 40px 0px #d3efe1 !important;
}

.selected {
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  padding: 4px 8px;
}
</style>
