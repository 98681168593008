<template>
  <VRow no-gutters>
    <v-menu v-model="hourMenuOpen" transition="scale-transition">
      <template v-slot:activator="{ props }">
        <div
          class="select-hour d-flex align-center py-2 cursor-pointer"
          v-bind="props"
        >
          {{
            typeof selectedHour == 'number'
              ? hourList[selectedHour].title
              : selectedHour
          }}
          <v-icon
            class="transition-smooth"
            :class="{ 'rotated-angle': hourMenuOpen }"
            color="black"
            icon="mdi-chevron-down"
          />
        </div>
      </template>
      <v-list density="compact" v-model="selectedHour" max-height="300">
        <v-list-item
          :value="hour.value"
          v-for="hour in hourList"
          :key="`hour-${hour.value}`"
          @click="handleSelectHour(hour.value)"
        >
          <v-list-item-title>{{ hour.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-model="minuteMenuOpen" transition="scale-transition">
      <template v-slot:activator="{ props }">
        <div
          class="select-minute d-flex align-center pl-2 pr-1 py-2 cursor-pointer"
          v-bind="props"
        >
          {{ selectedMinute }}
          <v-icon
            class="transition-smooth"
            :class="{ 'rotated-angle': minuteMenuOpen }"
            color="black"
            icon="mdi-chevron-down"
          />
        </div>
      </template>
      <v-list density="compact" v-model="selectedMinute" max-height="300">
        <v-list-item
          :value="minute"
          v-for="minute in minuteList"
          :key="minute"
          @click="handleSelectMinute(minute)"
        >
          <v-list-item-title>
            {{ minute }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </VRow>
</template>
<script lang="ts" setup>
import { ref, Ref } from 'vue'

const selectedMinute: Ref<string | number> = defineModel('minute')
const selectedHour: Ref<string | number> = defineModel('hour')

const hourList = Array.from({ length: 24 }, (_, i) => ({
  title: i + 'h',
  value: i,
}))

const hourMenuOpen = ref(false)
const handleSelectHour = hour => {
  selectedHour.value = hour
}

const minuteList = Array.from({ length: 60 }, (_, i) => i)
const minuteMenuOpen = ref(false)
const handleSelectMinute = minute => {
  selectedMinute.value = minute
}
</script>
<style scoped>
@import url('./style.css');

.select-minute {
  width: 70px;
  border: 1px solid #aaa;
  border-radius: 0 10px 10px 0 !important;
  background-color: #fff;
  position: relative;
  border-left: none;
  display: flex;
  justify-content: space-around;
  &:hover {
    background-color: #f8f8f8;
  }
}
.select-hour {
  width: 70px;
  display: flex;
  justify-content: center;
  border: 1px solid #aaa;
  border-radius: 10px 0 0 10px !important;
  background-color: #fff;
  position: relative;
  border-right: none;
  & .v-icon {
    position: absolute;
    right: 4px;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #aaa;
  }
  &:hover {
    background-color: #f8f8f8;
  }
}

@media screen and (max-width: 380px) {
  .select-hour,
  .select-minute {
    width: 84px;
    font-size: small;
  }
}
.select-profile-option-wrapper {
  position: absolute;
  top: 117px;
  z-index: 999;
  & .select-profile-option {
    background-color: #f4f4f4;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
}
</style>
