import { ref } from 'vue'
import { defineStore } from 'pinia'
import { ApiResponse } from '@/api/api'
import { typesenseClient } from '@/api/typesenseClient'
import { useDebounceFn } from '@vueuse/core'
import {
  Address,
  IAddressPractitioner,
  IAvailabilitiesOption,
  IItem,
  IPayloadAutocompleteServices,
  IPractitionerCard,
  IResponseQuerySuggestion,
  TFormattedListAutocompleteService,
} from '@/store/search-page/mainSearchBarStore.d'
import { useGeolocationStore } from '@/store/geolocation/geolocationStore'
import { findPractitioner } from '@/api/typesense/typesense'
import { SearchResponse } from '@/api/typesense/typesense.d'
import { latLng } from 'leaflet'
import i18n from '@/plugins/i18n'
import { formatSpokenLanguages } from '@/utils/formatSpokenLanguages'
import { formatAddress } from '@/utils/formatAddress'
import { convertirMetres } from '@/utils/convertirMetres'
import { formatPaymentMethods } from '@/utils/formatPaymentMethods'
import dayjs from 'dayjs'
import { LABEL_VERIFIED_DEGREES } from '@/shared/constants'
import MessageService from '@/components/feedback/message/messageService'
import router from '@/router'
import { useSearchFiltersStore } from '../search-filters/searchFiltersStore'

import { usePractitionerPublicProfileStore } from '../practitioner-public-profile/practitionerPublicProfileStore'

const { t } = i18n.global

const TITLE_TYPES = {
  discipline: 'Pratiques',
  practitioner_service: 'Actes et expertises',
}

const QUERY_SUGGESTION_PAYLOAD: IPayloadAutocompleteServices = {
  searches: [
    {
      collection: 'query_suggestions',
      filter_by: 'type: discipline',
      vector_query: 'embedding:([], distance_threshold:0.50, alpha: 0.3)',
    },
    {
      collection: 'query_suggestions',
      filter_by: 'type: practitioner_service',
      vector_query: 'embedding:([], distance_threshold:0.60, alpha: 0.7)',
    },
  ],
}

export const useMainSearchBarStore = defineStore('main-search-bar', () => {
  const DEFAULT_RADIUS = 100 // in km
  const DEFAULT_EXPANDED_RADIUS = 1000 // in km
  const PER_PAGE = 15 // practitioner per "page"
  const documentTypes = new Set() // type of documents : practitioner_service, discipline...
  const searchLocation = ref<string>(null)
  const radius = ref<number>(DEFAULT_RADIUS) // in km - radius used for the search

  const latitude = ref<number | null>(null)
  const longitude = ref<number | null>(null)
  const geolocationStore = useGeolocationStore()
  const searchFiltersStore = useSearchFiltersStore()
  const userPublicProfile = usePractitionerPublicProfileStore()
  const initializeGeolocation = async () => {
    await geolocationStore.initLocationService()
    latitude.value = geolocationStore.getLat()
    longitude.value = geolocationStore.getLon()
  }
  const countResultsFound = ref<number>(null)
  const isExpandedSearch = ref<boolean>(true)
  const searchText = ref('')

  /** services field autocomplete logic */
  const loadingServices = ref(false)
  const searchService = ref<string>(null)
  const listAutocompleteService = ref<TFormattedListAutocompleteService>([])
  const autocompleteSearchServices = useDebounceFn(async (search: string) => {
    loadingServices.value = true

    if (!search) search = '*' // by default
    searchText.value = search
    try {
      const response: ApiResponse<IResponseQuerySuggestion> =
        await typesenseClient.post(
          `/multi_search?q=${search}&include_fields=label,type,id&query_by=label,embedding&per_page=5&sort_by=_text_match:desc,q:asc`,
          QUERY_SUGGESTION_PAYLOAD,
        )

      listAutocompleteService.value = transformResponseToAutocompleteService(
        response.data,
      )
    } catch (error) {
      console.error(error)
    }

    loadingServices.value = false
  }, 200)

  const selectedDiscipline = ref('')
  const priceOrder = ref<'asc' | 'desc' | 'all'>('all')
  const sortBy = ref<
    | 'pertinence'
    | 'proximity'
    | 'price.asc'
    | 'price.desc'
    | 'opinions.asc'
    | 'opinions.desc'
  >('pertinence')
  const consultationType = ref<'all' | 'remote' | 'in-person'>('all')
  const opinionOrder = ref<'asc' | 'desc' | 'all'>('all')

  function transformResponseToAutocompleteService(
    response: IResponseQuerySuggestion,
  ): TFormattedListAutocompleteService {
    const formattedList: TFormattedListAutocompleteService = []

    response.results.forEach((result, index) => {
      if (!result.hits.length) return

      // Ajouter un sous-en-tête pour chaque type de document trouvé
      formattedList.push({
        type: 'subheader',
        title: TITLE_TYPES[result.hits[0].document.type], // format title from type
      })
      documentTypes.add(result.hits[0].document.type)

      // Limit to the 5 first
      result.hits.slice(0, 5).forEach(hit => {
        const document = hit.document
        // Ajouter un élément pour chaque document de type 'discipline' ou autr
        formattedList.push({
          value: document.label,
          title: document.label,
        })
      })

      if (index < response.results.length - 1)
        formattedList.push({ type: 'divider' })
    })

    return formattedList
  }
  /** end services field autocomplete logic */

  /** Find practitioner logic
   * the geolocation is stored in the local storage on change in the location field using the geolocation store
   */
  // if last elemn is visible and has more page, fetch next page <= increment page and fetch
  const loadingFind = ref(false)
  const listPractitioners = ref<IPractitionerCard[]>([])
  const pagination = ref({
    page: 1,
    perPage: PER_PAGE,
    countPage: null,
    hasMorePage: false,
  })
  const resetPagination = () => {
    pagination.value = {
      page: 1,
      perPage: PER_PAGE,
      countPage: null,
      hasMorePage: false,
    }
  }

  const findYourPractitioner = async (
    isLoadMore: boolean = false,
    excludedId: string = null,
  ) => {
    if (loadingFind.value) return
    if (!isLoadMore) {
      resetPagination()
    }
    // search : on remplace, load more, on ajoute
    await initializeGeolocation()
    const geolocationStore = useGeolocationStore()
    loadingFind.value = true
    radius.value = isExpandedSearch.value
      ? DEFAULT_EXPANDED_RADIUS
      : DEFAULT_RADIUS

    try {
      let search = searchService.value ? searchService.value : '*'

      // we don't need the filter on services on home page
      if (router.currentRoute.value.name === 'Home') {
        search = '*'
      }

      const latitude = geolocationStore.getLat()
      const longitude = geolocationStore.getLon()

      const filter_by = formatFilterByParam()

      if (searchFiltersStore.selectedDiscipline) {
        selectedDiscipline.value = searchFiltersStore.selectedDiscipline
      }

      if (searchFiltersStore.priceOrder) {
        priceOrder.value = searchFiltersStore.priceOrder
      }

      if (searchFiltersStore.consultationType) {
        consultationType.value = searchFiltersStore.consultationType
      }

      if (searchFiltersStore.opinionOrder) {
        opinionOrder.value = searchFiltersStore.opinionOrder
      }

      // TODO filter in function of filter selected
      const response: ApiResponse<SearchResponse> = await findPractitioner(
        search,
        latitude.toString(),
        longitude.toString(),
        radius.value.toString(),
        selectedDiscipline.value,
        priceOrder.value,
        sortBy.value,
        consultationType.value,
        // opinionOrder.value,
        filter_by,
        pagination.value.page,
        pagination.value.perPage,
      )

      // update number of results found
      if (response.data) countResultsFound.value = response.data.found
      if (!isExpandedSearch.value && !isLoadMore && response.data.found < 30) {
        // retry with expanded radius
        isExpandedSearch.value = true
        await findYourPractitioner(isLoadMore)
        return
      }
      // Update pagination
      pagination.value.countPage = Math.ceil(
        response.data.found / pagination.value.perPage,
      )
      pagination.value.hasMorePage =
        pagination.value.page < pagination.value.countPage

      // add to the list of practitioners
      if (isLoadMore) {
        listPractitioners.value = [
          ...listPractitioners.value,
          ...transformResponseToPractitionerCard(response.data),
        ]
        // used for leaflet map
        listAddresses.value = [
          ...listAddresses.value,
          ...transformAllPractitionerCardsToAddresses(listPractitioners.value),
        ]
      } else {
        // case it's not load more
        listPractitioners.value = transformResponseToPractitionerCard(
          response.data,
        )
        // used for leaflet map
        listAddresses.value = transformAllPractitionerCardsToAddresses(
          listPractitioners.value,
        )
      }
      listExtraFilterServices.value = [
        ...transformResponseToExtraFilterServices(response.data),
      ]

      // filter on practitionerId to display only one user and his profiles instead of 3 times the user for each profile
      listPractitioners.value = getUniquePractitioners(listPractitioners.value)
    } catch (error) {
      console.error(error)
      MessageService.error('Error')
    }
    loadingFind.value = false
  }

  // filter on practitionerId to display only one user and his profiles instead of 3 times the user for each profile
  function getUniquePractitioners(
    practitioners: IPractitionerCard[],
  ): IPractitionerCard[] {
    const uniquePractitionersMap: { [key: number]: IPractitionerCard } = {}

    practitioners.forEach(practitioner => {
      // uniquePractitionersMap[practitioner.practitionerId] = practitioner
      const existingPractitioner =
        uniquePractitionersMap[practitioner.practitionerId]

      if (
        !existingPractitioner ||
        practitioner.text_match > existingPractitioner.text_match
      ) {
        uniquePractitionersMap[practitioner.practitionerId] = practitioner
      }
    })

    return Object.values(uniquePractitionersMap)
  }

  const formatFilterByParam = () => {
    const filter_by = []

    const firstHourOfTheDay = day => {
      return day.hour(0).minute(0).second(0).millisecond(0).unix()
    }

    // Add availability filter
    if (extraFilterAvailabilities.value == 'today') {
      const todayLastTime = firstHourOfTheDay(dayjs().add(1, 'days'))
      filter_by.push(`addresses.firstAppointment:<${todayLastTime}`)
    } else if (extraFilterAvailabilities.value == 'next-three-days') {
      const inThreeDays = firstHourOfTheDay(dayjs().add(4, 'days'))
      filter_by.push(`addresses.firstAppointment:<${inThreeDays}`)
    } else if (extraFilterAvailabilities.value == 'in-the-week') {
      const nextWeekTimestamp = firstHourOfTheDay(dayjs().add(8, 'days'))
      filter_by.push(`addresses.firstAppointment:<${nextWeekTimestamp}`)
    }

    if (router.currentRoute.value.name === 'PractitionerPublicProfile') {
      filter_by.push(`id:!=${userPublicProfile.profile.id}`)
    }

    // Services filter
    if (extraFilterServices.value) {
      filter_by.push(
        `services:=[${extraFilterServices.value.map(item => `\`${item.value}\``)}]`,
      )
    }

    return filter_by.join(' && ')
  }

  const formatAudience = (audiences: string[]) => {
    const arrayAudience = []
    audiences.forEach(audience => {
      arrayAudience.push(t('profileForm.targetAudiences.audience.' + audience))
    })

    return arrayAudience.join(', ')
  }

  const transformResponseToPractitionerCard = (
    data: SearchResponse,
  ): IPractitionerCard[] => {
    return data.hits.map((hit): IPractitionerCard => {
      const { document, geo_distance_meters, text_match, highlight } = hit
      const {
        id,
        practitionerId,
        practitionerName,
        status,
        isVerified,
        labels,
        tagline,
        disciplines,
        spokenLanguages,
        addresses,
        priceMin,
        durationMin,
        durationMax,
        paymentMethods,
        avatar,
        remoteAllowed,
        mainDiscipline,
        targetAudiences,
        services,
        slug,
        reviews,
        opinionsPositive,
        reviewPercentage,
      } = document

      // Get the first address (assuming we want to use the first one)
      const address = addresses.length > 0 ? addresses[0] : null

      return {
        id,
        practitionerId,
        slug,
        practitionerName: practitionerName,
        verified: labels ? labels.includes(LABEL_VERIFIED_DEGREES) : false, // TODO wait the api
        isVerified,
        status: status,
        tagline: tagline,
        mainDiscipline,
        disciplines: disciplines,
        reviews: reviews ?? 0, // TODO when api ready get reviews
        opinionsPositive: opinionsPositive ?? 0, // TODO when api ready get reviews
        reviewPercentage: reviewPercentage ?? 0, // TODO when api ready get reviews
        audience: formatAudience(targetAudiences),
        languages: formatSpokenLanguages(spokenLanguages),
        address: {
          id: address.id, // TODO check when API ready
          addressLine: address.addressLine,
          city: address.city,
          country: address.country,
          location: address.location,
          latitude: address.location[0].toString(),
          longitude: address.location[1].toString(),
          postalCode: address.postalCode,
          street: address.street,
          formattedAddress: formatAddress(address),
          firstAppointment: dayjs
            .unix(address.firstAppointment)
            .format('YYYY-MM-DD HH:mm'),
          latLng: latLng(address.location[0], address.location[1]),
          hint: hit.geo_distance_meters?.['addresses.location']
            ? t('practitionerPublicProfile.distanceFromPatient', {
                distance: convertirMetres(
                  hit.geo_distance_meters['addresses.location'],
                ),
              })
            : '',
          remote: !!address.remote ?? false,
        },
        addresses: addresses.map(
          (addr): Address => ({
            id: addr.id, // TODO check when API ready
            addressLine: addr.addressLine,
            city: addr.city,
            country: addr.country,
            location: addr.location,
            latitude: addr.location[0].toString(),
            longitude: addr.location[1].toString(),
            postalCode: addr.postalCode,
            latLng: latLng(addr.location[0], addr.location[1]),
            formattedAddress: formatAddress(addr),
            firstAppointment: dayjs
              .unix(addr.firstAppointment)
              .format('YYYY-MM-DD HH:mm'),
            remote: !!address.remote ?? false,
          }),
        ),
        distanceKm: geo_distance_meters?.['addresses.location']
          ? geo_distance_meters['addresses.location'] / 1000
          : 0, // converting meters to kilometers
        priceFrom: priceMin,
        durationMin: durationMin,
        durationMax: durationMax,
        paymentMethods: formatPaymentMethods(paymentMethods),
        remoteAllowed,
        nextAppointment:
          address && address.firstAppointment
            ? dayjs.unix(address.firstAppointment).format('ddd D MMMM')
            : '',
        avatarUrl: avatar,
        isAvailabilitiesFetched: false,
        services,
        text_match: text_match,
        highlight: highlight,
      }
    })
  }
  /** end Find practitioner logic */

  // addresses
  const listAddresses = ref<IAddressPractitioner[]>([])

  function transformAllPractitionerCardsToAddresses(
    practitionerCards: IPractitionerCard[],
  ): IAddressPractitioner[] {
    return practitionerCards.flatMap(transformToAddressPractitioners)
  }

  function transformToAddressPractitioners(
    practitionerCard: IPractitionerCard,
  ): IAddressPractitioner[] {
    const {
      id,
      practitionerName,
      disciplines,
      priceFrom,
      durationMin,
      durationMax,
      addresses,
    } = practitionerCard

    return addresses.map(address => ({
      practitionerId: id,
      practitionerName,
      disciplines: disciplines.map(discipline => discipline.label),
      priceFrom,
      durationMin,
      durationMax,
      latLng: latLng(address.location[0], address.location[1]),
      formattedAddress: formatAddress(address),
      ...address,
    }))
  }

  /** extra filters - services and availabilities and remote */
  const extraFilterAvailabilities = ref<IAvailabilitiesOption>('')
  const extraFilterServices = ref<IItem[]>(null)
  const extraFilterRemote = ref<boolean>(false)

  const listExtraFilterServices = ref<TFormattedListAutocompleteService>([])

  function transformResponseToExtraFilterServices(
    response: SearchResponse,
  ): TFormattedListAutocompleteService {
    const formattedList: TFormattedListAutocompleteService = []

    // Adding a subheader for disciplines
    formattedList.push({
      type: 'subheader',
      title: 'Disciplines',
    })

    // Extracting disciplines and ensuring uniqueness
    const disciplines = new Set<string>()
    response.hits.forEach(hit => {
      hit.document.disciplines.forEach(discipline =>
        disciplines.add(discipline.label),
      )
    })

    // Adding disciplines to the list
    disciplines.forEach(discipline => {
      formattedList.push({
        value: discipline,
        title: discipline,
      })
    })

    // Adding a divider
    formattedList.push({ type: 'divider' })

    // Adding a subheader for services
    formattedList.push({
      type: 'subheader',
      title: 'Services',
    })

    // Extracting services and ensuring uniqueness
    const services = new Set<string>()
    response.hits.forEach(hit => {
      hit.document.services.forEach(service => services.add(service))
    })

    // Adding services to the list
    services.forEach(service => {
      formattedList.push({
        value: service,
        title: service,
      })
    })

    return formattedList
  }
  /** End extra filters */

  return {
    documentTypes,
    searchLocation,
    radius,
    isExpandedSearch,
    countResultsFound,

    extraFilterAvailabilities,
    extraFilterServices,
    extraFilterRemote,
    listExtraFilterServices,
    transformResponseToExtraFilterServices,

    searchService,
    listAutocompleteService,
    loadingServices,
    autocompleteSearchServices,
    transformResponseToAutocompleteService,

    listPractitioners,
    loadingFind,
    pagination,
    resetPagination,
    findYourPractitioner,
    formatFilterByParam,
    transformResponseToPractitionerCard,

    latitude,
    longitude,
    initializeGeolocation,

    listAddresses,
    transformToAddressPractitioners,
    transformAllPractitionerCardsToAddresses,

    formatAudience,
    selectedDiscipline,
    priceOrder,
    consultationType,
    opinionOrder,
    sortBy,
    searchText,
  }
})
