import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import {
  User,
  UserJwtPayload,
  availlableFeaturesSlug,
} from '@/store/user/userStore.d'
import { login as authLogin } from '@/api/auth/auth'
import { jwtDecode } from 'jwt-decode'
import dayjs from 'dayjs'
import { ApiResponse } from '@/api/api'
import { useStorage } from '@vueuse/core'
import { useRouter, useRoute } from 'vue-router'
import createHttpClient from '@/api/httpClient'
import * as Sentry from '@sentry/vue'
import { ProfileInformationData } from '@/api/profile/profile.d'
import { getProfiles } from '@/api/profile/profile'
import { AddressInformationData } from '@/api/account/address'
import { getAddresses } from '@/api/account/account'
import { useGeolocationStore } from '../geolocation/geolocationStore'
import MessageService from '@/components/feedback/message/messageService'
import i18n from '@/plugins/i18n'
import { useDialogTimeSlotStore } from '../timeslot/dialogTimeSlotStore'
import { useAskPatientPresenceToPractitionerStore } from '../askPatientPresenceToPractitioner/askPatientPresenceToPractitionerStore'
import { useDialogAvisConsultationStore } from '../dialogAvisConsultationStore/dialogAvisConsultationStore'
import { formatAddress } from '@/utils/formatAddress'
import { useDialogYourAbsenceStore } from '../dialogYourAbsenceStore/dialogYourAbsenceStore'
import { useAppStore } from '@/store/app'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'

const { t } = i18n.global
const httpClient = createHttpClient()

export const useUserStore = defineStore('user', () => {
  const user = useStorage('user', {} as User | undefined | null)
  const isLogged = ref(false)
  const router = useRouter()
  const route = useRoute()

  const appStore = useAppStore()
  // const subscriptionStore = useSubscriptionStore()

  const init = () => {
    const token = user.value?.token
    if (token) {
      const decoded = jwtDecode<UserJwtPayload>(token)
      const expirationDate = dayjs.unix(decoded.exp)
      if (dayjs().isBefore(expirationDate)) {
        isLogged.value = true
        Sentry.setUser({ id: user.value.id, email: user.value.email })
      } else {
        refreshToken()
      }
    }
  }

  const login = async () => {
    const response = await authLogin()
    handleLoginResponse(response)
  }

  const refreshToken = async () => {
    if (!user.value?.refreshToken) {
      return Promise.reject('No refresh token')
    }
    try {
      const response = await httpClient.post(
        '/refresh-token',
        { refreshToken: user.value.refreshToken },
        { headers: { 'Content-Type': 'application/json' } },
      )
      handleLoginResponse({
        status: response.status,
        data: response.data,
      } as ApiResponse<any>)
    } catch (error) {
      handleLoginResponse({
        status: error.status,
        data: error.data,
      } as ApiResponse<any>)
    }
  }

  const handleLoginResponse = async (response: ApiResponse<any>) => {
    if (response.status === 200) {
      user.value = response.data
      isLogged.value = true
      Sentry.setUser({ id: user.value.id, email: user.value.email })

      if (user.value.profilType === 'patient') {
        appStore.navRole = 'patient'
      }

      // Update geolocation in localstorage
      const geolocationStore = useGeolocationStore()
      geolocationStore.updateStoredLocation({
        city: response.data.appointmentCity,
        coords: {
          latitude: response.data.appointmentLatitude,
          longitude: response.data.appointmentLongitude,
        },
      })

      // lancer un call API pour chaque modale de consultation à la connexion
      if (user.value.profilType === 'practitioner') {
        // Store to ask if patient were present to practitioner
        const dialogAskPractitionerConsultationStore =
          useAskPatientPresenceToPractitionerStore()
        await dialogAskPractitionerConsultationStore.fetchConsultations()
      }

      if (
        user.value.profilType === 'patient' ||
        user.value.profilType === 'practitioner'
      ) {
        // Store to ask reviews to patient
        const dialogAvisConsultationStore = useDialogAvisConsultationStore()
        await dialogAvisConsultationStore.fetchConsultations()

        // Ask to confirm the absence to the patient
        const dialogYourAbsenceStore = useDialogYourAbsenceStore()
        await dialogYourAbsenceStore.fetchConsultations()
      }

      // if (
      //   user.value.profilType === 'practitioner' &&
      //   user.value.isSignUpIncomplete
      // ) {
      //   return await router.push({ name: 'Subscription' })
      // }
      return true
    } else {
      logout()
    }
  }

  const logout = async (redirect: boolean = true) => {
    const redirectPath = route.fullPath
    isLogged.value = false

    if (redirect) {
      await router.push({ name: 'Login', query: { redirectUrl: redirectPath } })
    }
    user.value = null
    localStorage.removeItem('user')
    localStorage.removeItem('addresses')
    localStorage.removeItem('profiles')
    localStorage.removeItem('vacations')
    localStorage.removeItem('appointmentReasons')
    localStorage.removeItem('dialogTimeSlotCreationProfileAddress')
    const subscriptionStore = useSubscriptionStore()
    subscriptionStore.resetSubscriptionStatusAfterLogout()
  }

  const profiles: Ref<ProfileInformationData[]> = ref([])
  const initProfiles = async () => {
    // try {
    //   const res: ApiResponse<ProfileInformationData[]> = await getProfiles()
    //   if (res.status !== 200) throw Error(JSON.stringify(res))
    //   profiles.value = res.data
    //   // Set a default profile in storage if the one already in storage doesn't exist anymore
    //   const dialogTimeSlotStore = useDialogTimeSlotStore()
    //   if (
    //     !dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.profile ||
    //     !profiles.value.some(
    //       profile =>
    //         profile.id ===
    //         dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.profile.id,
    //     )
    //   ) {
    //     dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.profile =
    //       profiles.value[0]
    //   }
    // } catch (error) {
    //   console.error(error)
    //   MessageService.error(t('profile.errorGetProfile'))
    // }
  }

  const addresses: Ref<AddressInformationData[]> = ref([])
  const initAddresses = async () => {
    // try {
    //   const res: ApiResponse<AddressInformationData[]> = await getAddresses()
    //   if (res.status != 200) throw Error(JSON.stringify(res))
    //   addresses.value = res.data.map(address => ({
    //     ...address,
    //     formattedAddress: formatAddress(address, true),
    //   }))
    //   // Set a default address in storage if the one already in storage doesn't exist anymore
    //   const dialogTimeSlotStore = useDialogTimeSlotStore()
    //   if (
    //     !dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.address ||
    //     !addresses.value.some(
    //       addr =>
    //         addr.id ===
    //         dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.address.id,
    //     )
    //   ) {
    //     dialogTimeSlotStore.dialogTimeSlotCreationProfileAddress.address =
    //       addresses.value[0]
    //   }
    // } catch (error) {
    //   console.error(error)
    // }
  }

  const unAuthorizedFeature = (
    featureSlug: availlableFeaturesSlug,
  ): boolean => {
    return user.value.features?.includes(featureSlug) ?? false
  }

  return {
    user,
    isLogged,
    init,
    login,
    refreshToken,
    handleLoginResponse,
    logout,
    profiles,
    initProfiles,
    addresses,
    initAddresses,
    unAuthorizedFeature,
  }
})
